import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ClipboardModule } from 'ngx-clipboard';
import { AccordionModule } from 'ngx-bootstrap/accordion';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { BannerComponent } from './components/banner/banner.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { SwapComponent } from './components/swap/swap.component';
import { LanguageComponent } from './components/language/language.component';
import { SharedModule } from './shared/shared.module';
import { ConnectComponent } from './modals/connect/connect.component';
import { ClaimComponent } from './modals/claim/claim.component';
import { ReferComponent } from './modals/refer/refer.component';
import { WertComponent } from './modals/wert/wert.component';
import { StakingV1DashboardComponent } from './components/staking-v1-dashboard/staking-v1-dashboard.component';
import { FeatureComponent } from './components/feature/feature.component';
import { ErrorComponent } from './components/error/error.component';
import { AboutComponent } from './components/about/about.component';
import { BullishByDesignComponent } from './components/bullish-by-design/bullish-by-design.component';
import { FaqComponent } from './components/faq/faq.component';
import { HowToBuyComponent } from './components/how-to-buy/how-to-buy.component';
import { NewsComponent } from './components/news/news.component';
import { RoadmapComponent } from './components/roadmap/roadmap.component';
import { TokenomicsComponent } from './components/tokenomics/tokenomics.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    SwapComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LanguageComponent,
    ConnectComponent,
    ClaimComponent,
    ReferComponent,
    WertComponent,
    StakingV1DashboardComponent,
    FeatureComponent,
    ErrorComponent,
    FeatureComponent,
    TokenomicsComponent,
    NewsComponent,
    HowToBuyComponent,
    ReferComponent,
    FaqComponent,
    WertComponent,
    RoadmapComponent,
    AboutComponent,
    BullishByDesignComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    NgxChartsModule,
    SharedModule,
    CarouselModule,
    ModalModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    BsDropdownModule.forRoot(),
    ClipboardModule,
    // HttpClientModule,
    AccordionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    provideHttpClient(withFetch()),
    provideClientHydration(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
