<div class="swapSection" [ngClass]="{'mt-4 mb-2': !isModal}">
  <div class="tab-container gap-2 mb-3" *ngIf="isModal">
    <button class="btn btn-light text-uppercase d-flex align-items-center justify-content-center" [ngClass]="{'selected': isNative}" (click)="changeTab(true)">
      <img src="/assets/images/svg-icons/{{modeName}}.svg" height="25" alt="" />
      <span class="px-2">{{modeName}}</span>
    </button>

    <button class="btn btn-light text-uppercase d-flex align-items-center justify-content-center" [ngClass]="{'selected': !isNative}" (click)="changeTab(false)">
      <img src="/assets/images/svg-icons/usdt.svg" height="25" alt="" />
      <span class="ps-2">USDT</span>
    </button>
  </div>

  <div class="text-center pb-2 font-16" *ngIf="walletAddress">
    <div class="ms-2 fw-semibold dashTitle text-black" *ngIf="isNative && !isWert">{{modeName}} {{"balance" | translate }} {{convertNum(nativeBal, 4)}}</div>
    <div class="ms-2 fw-semibold dashTitle text-black" *ngIf="!isNative && !isWert">USDT {{"balance" | translate }} {{convertNum(usdtBal, 4)}}</div>
  </div>


  <!-- ====== not started ====== -->

  <div class="body-section mt-2" *ngIf="status === SwapStatus.not_started" [ngClass]="{'mt-0': isModal}">

    <div class="row my-2">

      <div class="col-md-6 pe-md-2">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <label class="d-block font-12 text-black">{{"widget.pay_with" | translate : {tokenName} }} </label>
          <div class="text-secondary cursor-pointer font-12" (click)="max()">{{"widget.max" | translate}}</div>
        </div>

        <div class="amountField d-flex align-items-start">
          <input #swapIn type="text" appTwoDigitDecimalNumber [noDecimal]="isNative ? 10 : 6"
            class="form-control text-truncate " placeholder="0" [(ngModel)]="swapToken" (ngModelChange)="swapTokenUpdate.next($event)">
          <div class="amountType">
            <svg-icon src="./assets/images/svg-icons/{{isNative ? (modeName) : (isWert ? 'usd' : 'usdt')}}.svg" [svgStyle]="{ 'width.px':28,'height.px':28 }"></svg-icon>
          </div>
        </div>
      </div>

      <div class="col-md-6 ps-md-2 mt-3 mt-md-0">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <label class="d-block font-12 text-truncate text-black">{{"widget.amount_in" | translate : {cryptoName} }}</label>
        </div>

        <div class="amountField">
          <input type="text" appTwoDigitDecimalNumber [noDecimal]="0" class="form-control text-truncate "
            [(ngModel)]="baseToken" min="minAmount" (ngModelChange)="baseTokenUpdate.next($event)" pattern="\d*" placeholder="0">
          <div class="amountType">
            <img src="./assets/images/svg-icons/logo.png" height="28" width="28"/>
          </div>
        </div>
      </div>

      <!-- ----- low balance info and error section ----- -->

      <div class="col-12 text-center fs-8">
        <div class="lowBalance m-2 text-black" *ngIf="!isLowBalance && !isWert && walletAddress">{{"widget.gas_fees" | translate : {gasFees, modeName} }}</div>
        <div class="lowBalance m-2 text-black" *ngIf="isNative && isLowBalance && !isWert && walletAddress">{{"widget.low_gas_fees" | translate : {modeName } }}</div>
        <div class="lowBalance m-2 text-black" *ngIf="!isNative && isLowBalance && !isWert && walletAddress">{{lowGas ? ("widget.low_gas_fees" | translate : {modeName } ) : ("widget.low_usdt" | translate) }}</div>

        <div class="text-center mt-2 w-100 text-danger" *ngIf="baseToken && baseToken < minAmount && !isWert && walletAddress">
          {{"widget.min_crypto" | translate : {minAmount, cryptoName} }}
        </div>

        <div class="text-center mt-2 w-100 text-danger" *ngIf="baseToken && baseToken >= minAmount && isLowBalance && !isWert && walletAddress">
          {{"widget.swap_error" | translate : {gasFees, modeName, tokenName } }}
        </div>
      </div>

    </div>

    <div class="d-flex flex-column align-items-center my-2" *ngIf="walletAddress">

      <button type="button" class="btn btn-primary w-100" *ngIf="modeName=='ETH'"
        [disabled]="(isLowBalance && !isWert) || !swapToken || swapToken == 0" (click)="confirm(true)">{{"staking.buy_stake_for" | translate}} {{stakeData.apy}}% {{"staking.rewards" | translate}}
      </button>

      <div class="d-flex flex-column flex-md-row align-items-center justify-content-between my-2 w-100 gap-2" *ngIf="!isModal">

        <button type="button" class="btn btn-primary w-100"
          [disabled]="(isLowBalance && !isWert) || !swapToken || swapToken == 0" (click)="confirm()" translate>widget.buy_now
        </button>

        <button class="btn btn-secondary w-100" (click)="changeChain(modeName === 'ETH' ? 'BSC' : 'ETH')">
          <img src="/assets/images/svg-icons/{{modeName === 'ETH' ? 'BNB' : 'ETH'}}.svg" height="18" alt=""  class="me-2"/>
          <span>{{'widget.switch_to' | translate}} {{modeName === 'ETH' ? 'BNB' : 'ETH'}}</span>
        </button>
      </div>


    </div>
  </div>


  <!-- ====== show loader ====== -->

  <div class="body-section mt-1 text-center" *ngIf="status === SwapStatus.in_progess" [ngClass]="{'mt-0': isModal}">
    <img src="./assets/images/svg-icons/logo.png" alt="loading" class="statusIcon loading">
    <div class="fw-normal fw-bold font-16 px-2 my-1 text-uppercase text-black text-center">{{"widget.transaction_in_progress" | translate}}</div>
    <p class="fs-7 mb-1 text-black" *ngIf="!isStake">{{"widget.transction_info" | translate: {baseToken, cryptoName} }}</p>
    <p class="fs-7 mb-1 text-black" *ngIf="isStake">{{"staking.transction_info_stake" | translate: {baseToken} }}</p>
  </div>


  <!-- ====== show confirm ====== -->

  <div class="body-section mt-1 text-center" *ngIf="status === SwapStatus.confirm_pending" [ngClass]="{'mt-0': isModal}">
    <img src="./assets/images/svg-icons/logo.png" alt="loading" class="statusIcon loading">
    <div class="fw-semibold fs-6 my-1 text-uppercase text-center text-secondary">{{"confirm" | translate}}</div>
    <p class="fs-7 mb-1 text-black" *ngIf="!isStake">{{"widget.waiting_confirm" | translate : {cryptoName, tokenName } }}</p>
    <p class="fs-7 mb-1 text-black" *ngIf="isStake">{{"staking.waiting_confirm_stake" | translate }}</p>
    <p class="fs-7 mb-1 text-black" *ngIf="isStake">{{"staking.waiting_confirm_stake_2" | translate }}</p>
  </div>


  <!-- ====== show pending ====== -->

  <div class="body-section mt-1 text-center" *ngIf="status === SwapStatus.approval_pending" [ngClass]="{'mt-0': isModal}">
    <img src="./assets/images/svg-icons/logo.png" alt="loading" class="statusIcon loading">
    <div class="fw-semibold fs-6 my-1 text-uppercase text-secondary">{{"widget.authorise" | translate}}</div>
    <p class="fs-7 mb-1 text-black">{{"widget.waiting_approve" | translate : {cryptoName} }}</p>
  </div>


  <!-- ====== show success/completed ====== -->

  <div class="body-section mt-1 text-center" *ngIf="status === SwapStatus.complete" [ngClass]="{'mt-0': isModal}">
    <img src="./assets/images/popup-icons/success.svg" alt="complete" class="statusIcon">
    <div class="fw-semibold fs-6 my-1 text-uppercase text-success text-center">{{"widget.transaction_success" | translate}}</div>
    <p class="fs-7 mb-1 text-black mb-lg-4" *ngIf="isStake">{{"staking.transction_info_stake_success" | translate: {baseToken} }}</p>
    <p class="fs-7 mb-1 text-black mb-lg-4" *ngIf="!isStake">{{"widget.transction_info" | translate: {baseToken, cryptoName} }}</p>

    <div class="d-flex align-items-center justify-content-center" *ngIf="modeName =='ETH' && !isModal && !isStake">
      <button (click)="stakeNow()" class="btn btn-md mb-2 btn-primary w-100 fs-7 text-uppercase">{{"widget.stake_now" | translate}}</button>
    </div>
    <div class="d-flex align-items-center justify-content-center gap-2">
      <button (click)="viewUrl()" class="btn btn-primary w-50 fs-8 text-uppercase">{{"widget.view_transaction" | translate}}</button>
      <button (click)="startAgain()" class="btn btn-primary w-50 fs-8 text-uppercase">{{"widget.start_again" | translate}}</button>
    </div>
  </div>


  <!-- ====== show failed ====== -->

  <div class="body-section mt-1 text-center" *ngIf="status === SwapStatus.failed" [ngClass]="{'mt-0': isModal}">
    <img src="./assets/images/popup-icons/warning.svg" alt="failed" class="statusIcon">
    <div class="fw-semibold fs-6 my-1 text-uppercase text-danger text-center">{{"widget.transaction_failed" | translate}}</div>
    <p class="fs-7 mb-1 mb-lg-4 text-danger">{{"widget.view_transaction_info" | translate}}</p>
    <div class="d-flex align-items-center justify-content-center gap-2">
      <button (click)="viewUrl()" class="btn btn-primary w-50 fs-8 text-uppercase">{{"widget.view_transaction" | translate}}</button>
      <button (click)="startAgain()" class="btn btn-primary w-50 fs-8 text-uppercase">{{"widget.start_again" | translate }}</button>
    </div>
  </div>


  <!-- ====== show rejected ====== -->

  <div class="body-section mt-1 text-center" *ngIf="status === SwapStatus.rejected" [ngClass]="{'mt-0': isModal}">
    <img src="./assets/images/popup-icons/warning.svg" alt="failed" class="statusIcon">
    <div class="fw-semibold fs-6 text-uppercase text-danger my-1 text-center">{{"widget.transaction_rejected" | translate}}</div>
    <div class="d-flex align-items-center justify-content-center gap-2 mt-3">
      <button (click)="startAgain()" class="btn  btn-primary w-100 fs-7 bg-secondary text-primary text-uppercase">{{"widget.start_again" | translate }}</button>
    </div>
  </div>

</div>
