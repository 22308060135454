import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-tokenomics',
  templateUrl: './tokenomics.component.html',
  styleUrls: ['./tokenomics.component.scss'],
})

export class TokenomicsComponent implements AfterViewInit {

  address: string = '0x00000000000000000000000000XD';
  isMobile:boolean = false;
  windowSize: number = 0;
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSize = window.innerWidth;
    this.isMobile = this.windowSize <= 1024;
  }
  
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
  ) {
    if (isPlatformBrowser(this._platformId)) {
      this.windowSize = window.innerWidth;
      this.isMobile = this.windowSize <= 1024;
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.windowSize = window.innerWidth;
      this.isMobile = this.windowSize <= 1024;
    }
  }

  copyAddress = () => {
    if (isPlatformBrowser(this._platformId)) navigator.clipboard.writeText(this.address);
  };
}
