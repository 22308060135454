import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {
  slideOption: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    nav: false,
    autoplayTimeout: 2500,
    center:true,
    margin:30,
    responsive: {
      0: {
        items: 1.5,
      },
      768:{
        items: 2
      }
    },
  };
}
