import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})

export class ErrorComponent {

  constructor(
    private route: Router
  ){
    route.navigateByUrl('/en');
  }
}
