<section class="roadmap">
  <img src="/assets/images/svg-icons/yellow-bottom-border.svg" class="header-chain w-100 d-none d-lg-block" alt="border">
  <img src="/assets/images/svg-icons/yellow-bottom-border-mob.svg" class="header-chain d-block d-lg-none" alt="border">
  <div class="text-center">
    <h2 class="section-title">
      <span class="text-secondary me-2" translate>roadmap.title_1</span>
      <span class="text-white" translate>roadmap.title_2</span>
    </h2>
  </div>

  <div class="container mt-4">
    <div class="box-container roadmap-description mb-3">
      <p class="text-center mb-0 italic-font text-white" translate>
        roadmap.description
      </p>
    </div>
    <div class="d-flex justify-content-between flex-lg-row flex-column gap-5 mt-5">
      <div class="box-container project-milestones">
        <h3 class="text-center font-20 mb-4" translate>roadmap.project_milestone</h3>
        <div class="d-flex align-items-center gap-3 mb-3 mx-3">
          <span class="circle yellow"></span>
          <p class="mb-0 italic-font" translate>roadmap.milestone_title_1</p>
        </div>
        <div class="d-flex align-items-center gap-3 mb-3 mx-3">
          <span class="circle green"></span>
          <p class="mb-0 italic-font" translate>roadmap.milestone_title_2</p>
        </div>
        <div class="d-flex align-items-center gap-3 mb-3 mx-3">
          <span class="circle sky"></span>
          <p class="mb-0 italic-font" translate>roadmap.milestone_title_3</p>
        </div>
        <div class="d-flex align-items-center gap-3 mb-3 mx-3">
          <span class="circle purple"></span>
          <p class="mb-0 italic-font" translate>roadmap.milestone_title_4</p>
        </div>
        <div class="d-flex align-items-center gap-3 mb-3 mx-3">
          <span class="circle pink"></span>
          <p class="mb-0 italic-font" translate>roadmap.milestone_title_5</p>
        </div>
        <div class="d-flex align-items-center gap-3 mb-3 mx-3">
          <span class="circle red"></span>
          <p class="mb-0 italic-font" translate>roadmap.milestone_title_6</p>
        </div>
        <div class="d-flex align-items-center gap-3 mb-3 mx-3">
          <img
            src="/assets/images/jetpack-bull.webp"
            alt=""
            class="jetpack-bull"
          />
          <p class="mb-0 italic-font" translate>roadmap.milestone_title_7</p>
        </div>
      </div>
      <div class="box-container roadmap-graph">
        <img
          src="/assets/images/jetpack-bull.webp"
          alt=""
          class="jetpack-bull-top"
        />
        <div class="x-rank">
          <p>$120K</p>
          <p>$110K</p>
          <p>$100K</p>
          <p>$90K</p>
          <p>$80K</p>
          <p>$74k</p>
        </div>
      </div>
    </div>
  </div>
  <img src="/assets/images/roadmap-mob.webp" alt="roadmap" class="roadmap-bottom-image">
</section>
