import { Component } from '@angular/core';

@Component({
  selector: 'app-how-to-buy',
  templateUrl: './how-to-buy.component.html',
  styleUrls: ['./how-to-buy.component.scss']
})
export class HowToBuyComponent {

  items = [
    {
      title:"howtobuy.step_1",
      desc:"howtobuy.step_1_desc",
      img:"/assets/images/webp/bull-icon",
    },
    {
      title:"howtobuy.step_2",
      desc:"howtobuy.step_2_desc",
      img:"/assets/images/webp/bull-icon",
    },
    {
      title:"howtobuy.step_3",
      desc:"howtobuy.step_3_desc",
      img:"/assets/images/webp/bull-icon",
    },
    {
      title:"howtobuy.step_4",
      desc:"howtobuy.step_4_desc",
      img:"/assets/images/webp/bull-icon",
    }
  ]
   
}
