import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})

export class AboutComponent implements AfterViewInit {

  isMobile:boolean = false;
  windowSize: number = 0;

  slideOption: OwlOptions = {
    loop: false,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    dots: true,
    nav: false,
    autoplayTimeout: 2500,
    center:true,
    margin:30,
    responsive: {
      0: {
        items: 1,
      },
      768:{
        items: 2
      }
    },
  };

  items = [
    {
      head:"about.head_1",
      para:"about.para_1"
    },
    {
      head:"about.head_2",
      para:"about.para_2"
    },
    {
      head:"about.head_3",
      para:"about.para_3"
    },
  ]
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSize = window.innerWidth;
    this.isMobile = this.windowSize <= 1024 ? true : false;
  }

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
  ) { }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.windowSize = window.innerWidth;
      this.isMobile = this.windowSize <= 1024 ? true : false;
    }
  }
}
