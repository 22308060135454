import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, createUrlTreeFromSnapshot, UrlSerializer } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { verifyLanguage } from '../../shared/constants/app-enums';
import { WINDOW } from '../../services/window';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
// import { EventService } from 'src/app/services/event.service';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent {

  lang: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private dom: any,
    @Inject(WINDOW) private window: Window,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private urlSerializer: UrlSerializer
    // private eventService: EventService
  ) {
   this.route.params.subscribe(params => {
      const lang = params['lang'];
      if (lang && verifyLanguage(lang)) {
        this.lang = lang;
      } else if(this.window){
        if (isPlatformBrowser(this.platformId)) {
          const userLang = navigator?.language?.split('-')[0];
          this.lang = userLang && verifyLanguage(userLang) ? userLang : 'en';
        } else this.lang = 'en';
      } else this.lang = 'en';

      // console.log('route.params =',this.lang)
      // this.eventService.setLang(this.lang);
      this.translate.setDefaultLang('en');
      this.translate.use(this.lang);
      this.updateCanonicalUrl(this.lang)
    });
  }

  private updateCanonicalUrl(url: string): void {
    let base = 'https://btcbulltoken.com/'
    const currUrl = this.urlSerializer.serialize(
      createUrlTreeFromSnapshot(this.route.snapshot, ['.'])
  );

    const link: HTMLLinkElement | null = this.dom.querySelector('link[rel=canonical]');
    if (link) {
      link.href = base+url;
    } else {
      const newLink = this.dom.createElement('link');
      newLink.rel = 'canonical';
      newLink.href = base+url;
      this.dom.head.appendChild(newLink);
    }
  }
}
