<section class="feature-wrapper">
  <h3 class="fw-bold d-none d-md-block" translate>feature.title</h3>
  <div class="container-fluid">
    <owl-carousel-o [options]="slideOption" dir="ltr" class="d-flex">
      <ng-template carouselSlide *ngFor="let item of items">
        <img [src]="item.img" class="img-fluid mx-auto featured-img p-2" alt="" />
      </ng-template>
    </owl-carousel-o>
  </div>
</section>
