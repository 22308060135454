<div class="about" id="about">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12">
                <div class="d-sm-flex justify-content-between align-items-center box mx-auto">
                    <div class="left">
                        <h2 class="section-title">
                            <span class="text-secondary me-2" translate>about.head</span>
                            <span class="text-white" translate>about.head1</span>
                        </h2> 
                        <!-- <h2 class="text-white text" translate>about.head</h2> -->
                        <p class="text-white font-16 pt-1 pt-sm-3 pb-4" translate>about.para</p>
                        <a class="px-4 buy-btn text-secondary  fw-regular font-22" target="_blank" href="javascript:void(0);" translate><span class="ps-2" translate>about.about_btn</span></a>
                    </div>
                    <img src="/assets/images/webp/about-bull.webp" class="about-bull" alt="about-bull">

                </div>
               
               
            </div>

        </div>
        
        <div class="row mt-5 justify-content-center" *ngIf="!isMobile as boolean">
            <div class="wrap mx-2" *ngFor="let item of items">
                <h3 class="text-white font-22 text-center" translate>{{item.head}}</h3>
                <p class="font-15 text-center m-0 p-0" translate>{{item.para}}</p>
            </div>
        </div>
        
        <!-- <h1>{{isMobile}}</h1> -->

        <div class="row" *ngIf="isMobile as boolean">
            <owl-carousel-o [options]="slideOption" class="w-100">
                <ng-template carouselSlide class="my-5"  *ngFor="let item of items">
                    <div class="wrap mx-sm-2">
                        <h3 class="text-white font-22 text-center" translate>{{item.head}}</h3>
                        <p class="font-15 text-center m-0 p-0" translate>{{item.para}}</p>
                    </div>
                </ng-template>         
            </owl-carousel-o>
        </div>     
    </div>
</div>