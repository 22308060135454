<div class="p-4">
  <div class="d-flex justify-content-between align-items-center">
    <div>&nbsp;&nbsp;&nbsp;</div>
    <h4 class="text-center fw-semibold fs-5 m-0 text-white" *ngIf="currentStep === 0" translate>widget.buy_now</h4>
    <h4 class="text-center fw-semibold fs-5 m-0 text-white" *ngIf="currentStep === 1" translate>wert.title</h4>
    <svg-icon src="/assets/images/svg-icons/close-white.svg" [svgStyle]="{ 'width.px':14, 'height.px':14 }" class="cursor-pointer" (click)="modalRef.hide()"></svg-icon>
  </div>

  <div class="mt-4 content text-center" *ngIf="currentStep === 0">
    <p  class="text-white" translate>{{'wert.disclaimer' | translate: {token} }}</p>
    <button (click)="showWidget()" class="btn btn-primary bg-white text-black font-14 mt-2 mx-auto" translate>wert.continue</button>
  </div>

  <div class="mt-4 content text-center" *ngIf="currentStep === 1">
    <img src="/assets/images/svg-icons/logo.svg" alt="loading" class="worker">
    <p translate class="text-white" translate>wert.desc</p>
  </div>

</div>
