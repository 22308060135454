<section class="roadmap section-gap"  id="how-to-buy">
  <img src="/assets/images/svg-icons/yellow-bottom-border.svg" class="head-chain w-100 d-none d-lg-block" alt="border">
  <img src="/assets/images/svg-icons/yellow-bottom-border-mob.svg" class="head-chain d-block d-lg-none" alt="border">
  <div class="overflow-hidden pb-5">
  <div class="container">
    <div class="text-center mt-5">
      <h2 class="section-title mt-4">
        <span class="text-secondary me-2" translate>howtobuy.title_1</span>
        <span class="text-white" translate>howtobuy.title_2</span>
      </h2>
    </div>

    <div class="roadmap-wrapper d-lg-flex align-items-start mt-5 pt-lg-5" >
        
            <div class="box-wrap box-wrap-1">
                <img src="/assets/images/svg-icons/step-1.svg" alt="line" class="img-fluid step-1-bg" loading="lazy" />

                <img src="/assets/images/svg-icons/line-1.svg" alt="line" class="img-fluid line-1 d-none d-lg-block" loading="lazy" />
                <img src="/assets/images/svg-icons/line-1-mob.svg" alt="line" class="img-fluid line-1 d-block d-lg-none" loading="lazy" />
              <div class="box">
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="mb-0" translate>howtobuy.step_1</h3>
                    <img src="/assets/images/svg-icons/btcbull-icon.svg" alt="btcbull icon" class="img-fluid icon d-none d-lg-block" loading="lazy" />
                </div>
                <p class="mb-0 font-14 pe-4 me-1" translate>howtobuy.step_1_desc <a href="https://best.sng.link/Dnio2/x13h?_smtype=3" target="_blank" translate class="text-secondary">howtobuy.best_wallet</a>howtobuy.step_1_desc_2</p>
              </div>
            </div>

            <div class="box-wrap box-wrap-2">
                <img src="/assets/images/svg-icons/step-2.svg" alt="line" class="img-fluid step-1-bg" loading="lazy" />

                <img src="/assets/images/svg-icons/line-2.svg" alt="line" class="img-fluid line-2 d-none d-lg-block" loading="lazy" />
                <img src="/assets/images/svg-icons/line-2-mob.svg" alt="line" class="img-fluid line-2 d-block d-lg-none" loading="lazy" />
                <div class="box">
                  <div class="d-flex align-items-center justify-content-between">
                      <h3 class="mb-0" translate>howtobuy.step_2</h3>
                      <img src="/assets/images/svg-icons/btcbull-icon.svg" alt="btcbull icon" class="img-fluid icon  d-none d-lg-block" loading="lazy" />
                  </div>
                  <p class="mb-0 font-14 pe-4 me-1" translate>howtobuy.step_2_desc <a href="https://best.sng.link/Dnio2/x13h?_smtype=3" target="_blank" translate class="text-secondary">howtobuy.best_wallet</a>howtobuy.step_2_desc_2</p>
                </div>
              </div>

              <div class="box-wrap box-wrap-3">
                  <img src="/assets/images/svg-icons/step-2.svg" alt="line" class="img-fluid step-1-bg" loading="lazy" />

                <img src="/assets/images/svg-icons/line-3.svg" alt="line" class="img-fluid line-3 d-none d-lg-block" loading="lazy" />
                <img src="/assets/images/svg-icons/line-4.svg" alt="line" class="img-fluid line-4 d-none d-lg-block" loading="lazy" />
                <img src="/assets/images/svg-icons/line-2-mob.svg" alt="line" class="img-fluid line-2 d-block d-lg-none" loading="lazy" />
                <div class="box">
                  <div class="d-flex align-items-center justify-content-between">
                      <h3 class="mb-0" translate>howtobuy.step_3</h3>
                      <img src="/assets/images/svg-icons/btcbull-icon.svg" alt="btcbull icon" class="img-fluid icon me-3 d-none d-lg-block" loading="lazy" />
                  </div>
                  <p class="mb-0 font-14 pe-4 me-1" translate>howtobuy.step_3_desc</p>
                </div>
              </div>

              <div class="box-wrap box-wrap-4">
                  <img src="/assets/images/svg-icons/step-1.svg" alt="line" class="img-fluid step-1-bg" loading="lazy" />

                <img src="/assets/images/svg-icons/line-5.svg" alt="line" class="img-fluid line-5 d-none d-lg-block" loading="lazy" />
                <img src="/assets/images/svg-icons/line-2-mob.svg" alt="line" class="img-fluid line-2 d-block d-lg-none" loading="lazy" />
                <img src="/assets/images/svg-icons/line-5-mob.svg" alt="line" class="img-fluid line-5 d-block d-lg-none" loading="lazy" />
                <div class="box">
                  <div class="d-flex align-items-center justify-content-between">
                      <h3 class="mb-0" translate>howtobuy.step_4</h3>
                      <img src="/assets/images/svg-icons/btcbull-icon.svg" alt="btcbull icon" class="img-fluid icon  d-none d-lg-block" loading="lazy" />
                  </div>
                  <p class="mb-0 font-14 pe-4 me-1"  translate>howtobuy.step_4_desc</p>
                </div>
              </div>
       </div>
    </div>
  </div>
  <img src="/assets/images/svg-icons/grey-bottom-border.svg" class="footer-chain w-100 d-none d-lg-block" alt="border">
  <img src="/assets/images/svg-icons/grey-bottom-border-mob.svg" class="footer-chain d-block d-lg-none" alt="border">
</section>

