<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-12">
                <a class="cursor-pointer d-flex align-items-center p-0 d-flex justify-content-center mt-3 mb-4" routerLink="/{{currentLanguage}}/" #navigation
                    (click)="navigateToSection('home')">
                    <svg-icon src="/assets/images/svg-icons/logo.png"
                        [svgStyle]="{ 'width.px':'152','height.px':'53' }"></svg-icon>
                </a>

             <div>
                <a href="/assets/documents/Terms&Conditions-BTCBULL.pdf" target="_blank" class="mx-3 text-decoration-underline" translate>
                    footer_navigation.terms
                </a>
                <a href="/assets/documents/COOKIES-BTCBULL.pdf" target="_blank" class="mx-3 text-decoration-underline" translate>
                    footer_navigation.cookies
                </a>
                <a href="/assets/documents/PRIVACYDOC-BTCBULL.pdf" target="_blank" class="mx-3 text-decoration-underline" translate>
                    footer_navigation.privacy
                </a>
             </div>
             <p class="font-14 fw-regular pt-4" translate>footer_navigation.disclaimer</p>
            </div>
        </div>
    </div>
</div>