import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { EventService } from '../../services/event.service';
import { WalletConnectService } from '../../services/wallet-connect.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { convertNum, SwapStatus } from '../../shared/constants/app-enums';
import { WINDOW } from '../../services/window';
import { PopupService } from '../../services/popup.service';
import { environment } from '../../../environments/environment';
import { ClaimComponent } from '../../modals/claim/claim.component';
import { ConnectComponent } from '../../modals/connect/connect.component';
import { ReferComponent } from '../../modals/refer/refer.component';
import { Subscription } from 'rxjs';
import { SwapComponent } from '../swap/swap.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss'
})

export class BannerComponent implements OnInit, AfterViewInit, OnDestroy {
  
  @ViewChild(SwapComponent) swapComp: any;
  private subscriptions: Subscription[] = [];
  
  lang: any;
  isMobile: boolean = false;
  isBsNetwork: boolean = false;
  emailId = '';
  tokenSold = 0;
  maxAmount = 0;
  maxToken = 0;
  minToken = 0;
  hashKey: string = '';
  windowSize: number = 0;
  usdRaised = 0;
  currentStep = 0;
  selectedLanguage: any;
  userStaked: number = 0;
  currentLanguage: any;
  connectInterval: any;
  usdtBal = 0;
  ethBal = 0;
  userClaimable: number = 0;
  userEthClaimable: number = 0;
  userBscClaimable: number = 0;
  refreshId: any;
  refreshRate: number = 3000;
  dataLoaded: boolean = false;
  isSoldOut: boolean = false;
  futureToken: number = 0;
  salePrice: number = 0.0337;
  liveCounter: any = {_days: 0, _hours: 0, _minutes: 0, _seconds: 0, _diff: 0};
  isLive: boolean = true;
  claimMode: boolean = false;
  swapMode: string = 'native';
  isNative: boolean = true;
  isWert: boolean = false;
  referUrl: string = '';
  SwapStatus = SwapStatus;
  timeInterval: any;

  walletAddress: any;
  currentStoreData: any;
  status: any;
  tokenRemain: any;
  raisedPercent: any;
  tokenPercent: any;
  modeName: any;
  stakeData: any;
  tokenName = environment.tokenName;


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.windowSize <= 1024 ? true : false;
  }
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(WINDOW) private window: Window,
    private eventService: EventService,
    private translate: TranslateService,
    private popupService: PopupService,
    private modalService: BsModalService,
    private walletConnectService: WalletConnectService,
    private modalRef: BsModalRef,
  ){
    
    
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.lang = params.lang;
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.windowSize = window.outerWidth;
      this.isMobile = this.windowSize <= 1024 ? true : false;

      this.refreshId = setInterval(() => this.getBalanceData(), this.refreshRate);
      this.timeInterval = setInterval(() => this.setTimer(), 4000 );
      this.subscriptions.push(this.eventService.getCounter().subscribe((val) => {
        this.liveCounter = val;
      }));
    }
  }

  getBalanceData() {
    const balanceData: any = this.walletConnectService.balanceData;
    this.currentStep = balanceData.currentStep;
    this.tokenSold = balanceData.totalTokensSold;
    this.currentStoreData = this.walletConnectService.tokenomics[this.currentStep]
    this.maxAmount = this.currentStoreData.maxAmount || 0;
    this.maxToken = this.currentStoreData.maxToken || 0;
    this.minToken = this.currentStoreData.minToken || 0;
    this.ethBal = balanceData.nativeBal;
    this.usdtBal = balanceData.usdtBal;
    this.usdRaised = balanceData.usdRaised;
    this.userStaked = this.walletConnectService.stakeData.userStaked;
    this.userClaimable = balanceData.userClaimable;
    this.userEthClaimable = balanceData.ethClaimable;
    this.userBscClaimable = balanceData.bscClaimable;
    this.referUrl = this.walletConnectService.referUrl;
    this.status = this.walletConnectService.swapStatus;
    this.tokenRemain = this.maxToken - this.tokenSold;
    this.walletAddress = this.walletConnectService.walletAddress;
    this.modeName = this.walletConnectService.getChainData().purchaseToken;
    this.stakeData = this.walletConnectService.stakeData;
    this.salePrice = this.currentStoreData.tokenUSDT
    this.raisedPercent = this.getRaisedPercent();
    this.tokenPercent = this.getTokenPercent();
    this.dataLoaded = true;
    
  }

  connectModal(bwMode: boolean = false): void {
    this.modalRef = this.modalService.show(ConnectComponent, {
      class: 'modal-min modal-dialog-centered',
      backdrop: 'static',
      initialState:{ bwMode: bwMode}
    });
  }

  referAndEarn(): void {
    this.modalRef = this.modalService.show(ReferComponent, {
      class: 'modal-min modal-dialog-centered',
      backdrop: 'static',
      initialState: { url: this.referUrl }
    });
  }

  convertNum(amount: number) {
    return convertNum(amount);
  }

  openSwap(isNative: boolean = true, isWert: boolean = false): void {
    if (this.swapComp.wertMode) {
      this.popupService.messagePopup("warning", "",
        this.translate.instant("popup.are_you_sure"),
        this.translate.instant("misc.Confirm"),
        this.translate.instant("misc.Cancel"))
        .then((result: any) => {
          //  Read more about isConfirmed, isDenied below 
          if (result.isConfirmed) {
            this.swapComp.wertMode = false;
            this.swapMode = isNative ? 'native' : isWert ? 'card' : 'usdt';
            this.isNative = isNative;
            this.isWert = isWert;
          }
        });
    } else {
      this.swapMode = isNative ? 'native' : isWert ? 'card' : 'usdt';
      this.isNative = isNative;
      this.isWert = isWert;
    }
    setTimeout(() => this.swapComp.max(), 1000);
  }

  checkBscChaim() {
    if (!this.walletConnectService.isEthChain()) {
      return true;
    } else {
      this.popupService.messagePopup(
        "info",
        this.translate.instant('popup.wrong_chain', { chainName: environment.eth.chainName }),
        this.translate.instant('popup.change_network'))
        .then((res) => {
          if (res.isConfirmed) {
            this.walletConnectService.switchNetwork(environment.eth.chainIdInt);
          }
        });
      return false;
    }
  }

  changeChain(mode: string): void {
    if (mode == this.modeName) return;
    const chain = mode === "ETH" ? environment.eth : environment.bsc;

    if (this.modeName == "ETH") {
      this.popupService.messagePopup('info',
        this.translate.instant("popup.change_swap_text", { apy: this.stakeData.apy, mode: mode }),
        this.translate.instant("popup.are_you_sure"),
        this.translate.instant("popup.buy_with_mode", { mode: mode }),
        this.translate.instant("misc.cancel"))
        .then((result: any) => {
          // Read more about isConfirmed, isDenied below
          if (result.isConfirmed) {
            if (this.walletAddress) {
              this.walletConnectService.switchNetwork(chain.chainIdInt);
            } else {
              this.walletConnectService.chainId = chain.chainIdInt;
            }
          }
        });
    } else {
      if (this.walletAddress) {
        this.walletConnectService.switchNetwork(chain.chainIdInt);
      } else {
        this.walletConnectService.chainId = chain.chainIdInt;
      }
    }
  }

  getRaisedPercent() {
    let percent = (this.usdRaised / this.maxAmount ) * 100;
    return (percent >= 100 ? 100 : percent) + '%';
  }

   getTokenPercent() {
    let percent = ((this.tokenSold - this.currentStoreData.minToken) / (this.maxToken - this.minToken)) * 100; //for presale
    return (percent >= 100 ? 100 : +(percent.toFixed(2))) + "%";
  }



  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  setTimer() {
    if(this.dataLoaded){
      if (this.tokenSold && this.tokenSold > 0) {
        if (this.currentStoreData.endDate < Date.parse(new Date().toString()) && this.currentStep < this.walletConnectService.tokenomics.length - 1) {
          this.eventService.setCounter(this.walletConnectService.tokenomics[this.currentStep + 1].endDate);
        } else {
          this.eventService.setCounter(this.currentStoreData.endDate);
        }
        clearInterval(this.timeInterval);
      }
    }
  }

  async copy() {
    await this.popupService.messsageAlert('success', this.translate.instant("misc.successCopy"));
  }

  wertWidget(popup: any) {
    this.modalRef = this.modalService.show(popup, {
      class: 'modal-dialog-centered',
      backdrop: 'static'
    });
  }

  yourPurchasedInfo() {
    this.popupService.messagePopup("info", this.translate.instant("popup.your_purchased_popup_info"));
  }

  changeToChain() {
    this.popupService.messagePopup("info", this.translate.instant("popup.change_chain_info"));
  }

  navigateToSection(section: string) {
    this.hashKey = section;
    if (section) {
      window.location.hash = section;
      // window.location.pathname = '/' + this.currentLanguage;
    }
  }
  stakeInfo() {
    this.popupService.messagePopup("info", this.translate.instant("staking.stake_popup_info"));
  }

  /*async claim() {
    if (this.userClaimable !== 0 || this.checkBscChaim()) {
      await this.walletConnectService.claim();
      this.modalRef = this.modalService.show(ClaimComponent, {
        class: 'modal-xs modal-dialog-centered',
        backdrop: 'static',
      });
    }
  }*/

  /* claimStake(): void {
   if (this.userClaimable == 0 || !this.checkBscChaim())
      return;
    this.walletConnectService.claimStake();
    this.modalRef = this.modalService.show(ClaimComponent, {
      class: 'modal-xs modal-dialog-centered',
      backdrop: 'static',
      initialState: { isStake: true }
    });
  }*/


  connectWallet(mode: string): void {
    this.walletConnectService.connectWallet(mode, this.isBsNetwork);
    this.modalRef.hide();
  };

  openBestWallet(template: TemplateRef<any>) {
    this.modalRef.hide();
    setTimeout(()=> {
      this.modalRef = this.modalService.show(template, {
        class: 'modal-md modal-dialog-centered',
        backdrop: 'static',
      });
    }, 500)
  }
}
