import { Component } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SwapStatus } from '../../shared/constants/app-enums';
import { EventService } from '../../services/event.service';
import { WalletConnectService } from '../../services/wallet-connect.service';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})

export class ClaimComponent {
  // SwapStatus = SwapStatus;
  // isStake = false;
  // lang = 'en';
  // isWithdraw = false;
  // constructor(
  //   private eventService: EventService,
  //   public modalRef: BsModalRef,
  //   public walletConnect: WalletConnectService,
  //   private translate: TranslateService
  // ) {
  //   this.eventService.getLang().subscribe((val) => {
  //     this.lang = val || 'en';
  //   });
  //   this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
  //     this.lang = params.lang;
  //   });
  // }

  // get status() {
  //   return this.walletConnect.swapStatus;
  // }
}
