import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LanguageComponent } from './components/language/language.component';
import { StakingV1DashboardComponent } from './components/staking-v1-dashboard/staking-v1-dashboard.component';
import { languages } from './shared/constants/app-enums';
import { ErrorComponent } from './components/error/error.component';

const routesLang: Routes = languages.map((lang) => {
  return {
    path: lang.value,
    component: LanguageComponent,
    data: {
      canonical: lang.value
    },
    children: [
      {
        path: '', component: HomeComponent, data: {
          canonical: lang.value
        }
      },
      {
        path: 'dashboard', component: StakingV1DashboardComponent, data: {
          canonical: lang.value + '/dashboard'
        }
      },
    ]
  }
});

const routes: Routes = [
  {
    path: '',
    component: LanguageComponent,
    data: {
      canonical: 'en'
    },
    children: [
      { path: '', component: HomeComponent, data: {
        canonical: 'en'
      } },
      { path: 'dashboard', component: StakingV1DashboardComponent,data: {
        canonical: 'en/dashboard'
      } },
    ]
  },
  {
    path: ':lang',
    component: LanguageComponent,
    data: {
      canonical: '/'
    },
    children: [
      { path: '', component: HomeComponent, data: {
        canonical: '/'
      } },
      { path: 'dashboard', component: StakingV1DashboardComponent,data: {
        canonical: '/dashboard'
      } },
    ]
  },
  ...routesLang,
  { path: '**', redirectTo: 'en', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
