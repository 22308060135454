<section class="tokenomics" id="tokenomics">

  <div class="tokenomics-details-container container">
    <div class="text-center">
      <h2 class="section-title">
        <span class="text-secondary me-2" translate>tokenomics.title_1</span>
        <span class="text-white" translate>tokenomics.title_2</span>
      </h2>
    </div>
    <div
      class="d-flex align-items-center justify-content-between ranking-section"
    >
      <div class="d-flex flex-column align-items-center rank-card">
        <svg-icon src="/assets/images/svg-icons/tokenomic-circle-1.svg"></svg-icon>
        <span class="fw-bold font-12">50.00%</span>
        <p translate>tokenomics.card_1_title</p>
      </div>
      <div class="d-flex flex-column align-items-center rank-card">
        <svg-icon src="/assets/images/svg-icons/tokenomic-circle-2.svg"></svg-icon>
        <span class="fw-bold font-12">15.00%</span>
        <p translate>tokenomics.card_2_title</p>
      </div>
      <div class="d-flex flex-column align-items-center rank-card">
        <svg-icon src="/assets/images/svg-icons/tokenomic-circle-3.svg"></svg-icon>
        <span class="fw-bold font-12">10.00%</span>
        <p translate>tokenomics.card_3_title</p>
      </div>
      <div class="d-flex flex-column align-items-center rank-card">
        <svg-icon src="/assets/images/svg-icons/tokenomic-circle-4.svg"></svg-icon>
        <span class="fw-bold font-12">10.00%</span>
        <p translate>tokenomics.card_4_title</p>
      </div>
      <div class="d-flex flex-column align-items-center rank-card">
        <svg-icon src="/assets/images/svg-icons/tokenomic-circle-5.svg"></svg-icon>
        <span class="fw-bold font-12">10.00%</span>
        <p translate>tokenomics.card_5_title</p>
      </div>
      <div class="d-flex flex-column align-items-center rank-card">
        <svg-icon src="/assets/images/svg-icons/tokenomic-circle-6.svg"></svg-icon>
        <span class="fw-bold font-12">5.00%</span>
        <p translate>tokenomics.card_6_title</p>
      </div>
    </div>
    <p class="text-center mt-4 opacity-50" translate>
      tokenomics.token_address <span>: {{address}}</span>
      <svg-icon
        src="/assets/images/svg-icons/tokenomic-copy.svg"
        class="cursor-pointer ms-1"
        [svgStyle]="{ 'width.px': '13', 'height.px': '13' }"
      ></svg-icon>
    </p>
  </div>
  <img src="/assets/images/tokenomics-cartoon.webp" alt="tokenomics-cartoon" class="tokenomics-cartoon">
  <img src="/assets/images/svg-icons/yellow-bottom-border.svg" class="footer-chain w-100 d-none d-lg-block" alt="border">
  <img src="/assets/images/svg-icons/yellow-bottom-border-mob.svg" class="footer-chain d-block d-lg-none" alt="border">
</section>
