import { ApplicationRef, Component, Inject, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { getParam } from './shared/constants/app-enums';
import { EventService } from './services/event.service';
import { DOCUMENT } from '@angular/common';
declare global {
  interface Window {
    dataLayer: any[];
  }
}
export var selectedLang: string;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  canRender: boolean = true;

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private meta: Meta,
    private title: Title,
    private eventService: EventService,
    private translate: TranslateService,
  ) {

    inject(ApplicationRef).isStable.subscribe(isStable => this.canRender = isStable || this.canRender);

    getParam('clickId');
    getParam('source');
    this.eventService.getLang().subscribe((val) => {
      selectedLang = val || 'en';
    });

    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      selectedLang = params.lang;
    });

    /* translate.onLangChange.subscribe((params: DefaultLangChangeEvent) => {
       const metaData = this.translate.instant('meta_data');
       const metaTitle = this.translate.instant('meta_title');
       console.log('metaData =', metaData)
       this.title.setTitle(metaTitle);
       this.meta.updateTag({ name: 'description', content: metaData });
     });*/
  }
}
