<section class="bullish-by-design" id="bullish">
    <img src="/assets/images/svg-icons/grey-bottom-border.svg" class="head-chain w-100 d-none d-lg-block" alt="border">
    <img src="/assets/images/svg-icons/grey-bottom-border-mob.svg" class="head-chain d-block d-lg-none" alt="border">
  <div class="text-center">
    <h2 class="section-title">
      <span class="text-secondary me-2" translate>bullish.title_1</span>
      <span class="text-white" translate>bullish.title_2</span>
    </h2>
    <div class="container mt-5">
      <p class="text-center mt-2 mb-2 font-15 justify-center" translate>
        bullish.para
      </p>
    </div>
  </div>
    <img src="/assets/images/svg-icons/bull-bullish.svg" alt="bull" class="img-fluid bull" />
</section>
