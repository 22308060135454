
export const environment = {
  production: false,

  eth: {
    chainId: '0xaa36a7', //hexadecimal for metamask
    chainIdInt: 11155111,
    chainName: 'Ethereum Mainnet',
    gasFees: 0,
    purchaseToken: 'ETH',
    hasStaking: true,
    nativeFunction: 'buyWithEth',
    presaleAddress: '0x8b83ffdf0fb1beCd30593DC169b743cAA7BF9e00',
    usdTAddress: '0x9fa2AE3c0a571BbBFd17E74d7470b6c444A87aF2',
    rpcURL: 'https://sparkling-fabled-forest.quiknode.pro/9cce010f339320ea3d7fd6eca5a83271bfef0741/',
    backupRPC: 'https://sparkling-fabled-forest.quiknode.pro/9cce010f339320ea3d7fd6eca5a83271bfef0741/',
    blockExplorerURL: 'https://etherscan.io/tx/',
    chainInfo: {
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0xaa36a7',
          chainName: 'Ethereum Mainnet',
          nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.ankr.com/eth'],
          blockExplorerUrls: ['https://etherscan.io/'],
        },
      ],
    },
  },

  bsc: {
    chainId: '0x61', //hexadecimal for metamask
    chainIdInt: 97,
    chainName: 'BSC Mainnet',
    gasFees: 0,
    purchaseToken: 'BNB',
    hasStaking: false,
    nativeFunction: 'buyWithBNB',
    presaleAddress: '0x482439f16c59545522b037cAb2BD0b1A6035Ae6B',
    usdTAddress: '0xADFc9B54FB7a1677176A7529F03b91081C91355c',
    rpcURL: 'https://warmhearted-warmhearted-shape.bsc.quiknode.pro/0a3b464c34ae5ff77231c6767d89108472a0199d/',
    backupRPC: 'https://warmhearted-warmhearted-shape.bsc.quiknode.pro/0a3b464c34ae5ff77231c6767d89108472a0199d/',
    blockExplorerURL: 'https://bscscan.com/tx/',
    chainInfo: {
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0x61',
        chainName: 'BSC Mainnet',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed2.ninicoin.io/'],
        blockExplorerUrls: ['https://bscscan.com/'],
      }]
    }
  },

  minToken:         0,
  tokenAddress:     '0x0000000000000000000000000000000000000000',
  stakingAddress:   '0xDad7d39907E91eC0Faa25657D0823962D5698Ca7',
  apyCalcConst:     '416666667',
  tokenName:        '$BTCBULL',
  walletConnectId:  '5d51abeed848078f8f4a3f8f843f93eb',
  iidDashFx:        0,
  urlDashFx:        '',
  wertOrigin:       'https://sandbox.wert.io',                                                    // testnet
  partnerId:        '01HK57C8XMFEVRTMAW55NBD9W2',                                                 // testnet
  wertUrl:          'https://ijs2jkwmnzohnnjppqkypl2avq0hhlcm.lambda-url.eu-west-2.on.aws/',      // testnet
  referUrl:         '',
  bwUniversalLink:  'https://best-wallet-dev.web.app',
  bwDeepLink:       'bw-dev://app/connect?',
  bwApiUrl:         'https://test-api.bestwallet.com/presale-transactions',
  showBestWallet:   false,
};
