<div class="banner" [ngClass]="{'arabic': lang === 'ar'}" id="home">
  <div class="container">

    <div class="bannerSec row">

      <!-- ======= Banner Left Content ======= -->

      <div class="col-12 col-md-7 leftSec">
        <div class="banner-content ">
          <img src="/assets/images/banner-logo.webp" alt="logo" class="top-logo">
          <h1 class="font-18 font-md-25 banner-desc ps-lg-4 text-center text-lg-start" translate>banner.banner_desc</h1>
          <h2 class="font-14 bannerdesc2 ps-lg-4 mt-lg-3 text-center text-lg-start" translate>banner.banner_desc_2</h2>

          <div class="d-flex justify-content-center justify-content-md-start mb-1 pt-3 gap-3 ps-lg-4">
            <a class="px-lg-5 px-4 text-center btn-outline-primary font-16 banner-btn" target="_blank" href="javascript:void(0);" translate>misc.audit</a>
            <a class="px-lg-5 px-4 text-center btn-outline-primary font-16 banner-btn" target="_blank" href="javascript:void(0);" translate>misc.whitepaper</a>
          </div>
        </div>
      </div>



      <!-- ======= Banner right Content ======= -->

      <div class="col-12 col-md-5 rightSec align-self-center">

        <div class="walletBox">
          <div class="w-100 d-flex flex-column align-items-center justify-content-start text-center">

            <p class="font-19 text-uppercase fw-bold mb-3 text-black" *ngIf="!isSoldOut">{{"widget.widget_title" | translate }}
            </p>
            <p class="font-19 fw-bold mb-3 text-black" *ngIf="isSoldOut">{{"widget.sold_out_stage" | translate }} {{currentStep - 2}}</p>

            <!-- ======= counter ======= -->

            <div class="d-flex align-items-center justify-content-center w-100 gap-2 counter">
              <div class="time-card text-center d-flex" [ngClass]="{'shimmer': liveCounter._diff == 0}">
                <div class="value">{{liveCounter._days}}</div>
                <div class="indicator" translate>widget.days</div>
              </div>
              <div class="time-card text-center" [ngClass]="{'shimmer': liveCounter._diff == 0}">
                <div class="value">{{liveCounter._hours}}</div>
                <div class="indicator" translate>widget.hours</div>
              </div>
              <div class="time-card text-center" [ngClass]="{'shimmer': liveCounter._diff == 0}">
                <div class="value">{{liveCounter._minutes}}</div>
                <div class="indicator" translate>widget.minutes</div>
              </div>
              <div class="time-card text-center" [ngClass]="{'shimmer': liveCounter._diff == 0}">
                <div class="value">{{liveCounter._seconds}}</div>
                <div class="indicator" translate>widget.seconds</div>
              </div>
            </div>

            <p class="my-3 fs-7 text-center text-black" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">
              <span class="text-uppercase fw-semibold"> {{"widget.usdt_raised" | translate}} </span>
              <span class="fw-regular">${{convertNum(usdRaised)}}</span>
              <span class="fw-regular "> / ${{convertNum(maxAmount)}}</span>
            </p>

            <div class="progress " data-percent="0" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}" *ngIf="!isSoldOut">
              <div class="bar" [style.width]="raisedPercent"></div>
              <div class="status"  [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}" translate>widget.bar_title</div>
            </div>


            <div class="d-flex justify-content-center align-items-center text-center mb-2 font-12 text-black px-3 mt-3"
              [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">
              <span class="text-uppercase fw-semibold">{{"widget.your_eth_claimable" | translate :{tokenName} }}</span>
              <span class=""> = {{convertNum((userStaked + userClaimable))}}</span>
              <img src="/assets/images/svg-icons/info-icon.svg" class="img-fluid ms-2 cursor-pointer" (click)="yourPurchasedInfo()" alt="">
            </div>

            <div class="d-flex justify-content-center align-items-center text-center text-black font-12 px-3 mb-3"
              [ngClass]="[(!usdRaised || usdRaised <=0) ? 'shimmer' :'']">
              <span class="text-uppercase fw-semibold">{{"widget.your_bsc_claimable" | translate :{tokenName} }}</span>
              <span class="fw-regular"> = {{convertNum(userEthClaimable)}}</span>
              <img src="/assets/images/svg-icons/info-icon.svg" class="img-fluid ms-2 cursor-pointer" (click)="stakeInfo()" alt="">
            </div>

            <button type="button" class="btn btn-sm btn-black mb-4 mt-2 mx-auto"
              (click)="referAndEarn()" *ngIf="walletAddress && referUrl != ''" translate>widget.refer_and_earn
            </button>
          </div>

          <div class="swapArea text-black " *ngIf="!isSoldOut"
            [ngClass]="{'cover': status === SwapStatus.in_progess || status === SwapStatus.confirm_pending || status === SwapStatus.approval_pending}">
            <p class="text-center mb-3 font-14 fw-semibold dashTitle" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">1
              {{tokenName}} = ${{salePrice}}</p>

            <div class="tab-container gap-2">
              <button class="btn text-uppercase gap-2 w-100" [ngClass]="{'selected': swapMode ==='native'}" (click)="openSwap(true)">
                <img src="/assets/images/svg-icons/{{modeName}}.svg" height="26" alt="" />
                <span class="fw-regular">{{modeName}}</span>
              </button>

              <button class="btn text-uppercase gap-2 w-100" [ngClass]="{'selected': swapMode ==='usdt'}" (click)="openSwap(false)">
                <img src="/assets/images/svg-icons/usdt.svg" height="26" alt="" />
                <span class="fw-semibold">USDT</span>
              </button>

              <button class="btn text-uppercase gap-2 w-100" [ngClass]="{'selected': swapMode ==='card'}" (click)="openSwap(false, true)">
                <img src="/assets/images/svg-icons/card.svg" height="26" alt="" class="float-start" />
                <span class="fw-semibold" translate>card</span>
              </button>
            </div>

            <app-swap [isNative]="isNative" [isWert]="isWert" #swap></app-swap>

            <div class="d-flex align-items-center justify-content-center gap-2 mt-4" *ngIf="!walletAddress">
              <button class="btn btn-black w-100" (click)="connectModal()">
                <span translate>misc.connect_wallet</span>
              </button>

              <button class="btn btn-black w-100 px-2" (click)="changeChain(modeName === 'ETH' ? 'BSC' : 'ETH')">
                <img src="/assets/images/svg-icons/{{modeName === 'ETH' ? 'BNB' : 'ETH'}}.svg" height="18" alt=""  class="me-2" />
                <span>{{'widget.switch_to' | translate}} {{modeName === 'ETH' ? 'BNB' : 'ETH'}}</span>
              </button>
            </div>

            <div class="mt-3">
              <p class="font-13 text-center mb-1">
                <a *ngIf="modeName=='ETH' && walletAddress" class="text-decoration-underline cursor-pointer text-black"
                  href="https://widget.wert.io/default/widget/?commodity=ETH%3AEthereum" target="_blank"
                  translate>widget.not_enough_eth
                </a>
              </p>

              <!--- dont have wallet-->
              <a href="https://best.sng.link/Dnio2/x13h?_smtype=3" target="_blank"  class="d-block d-md-none text-decoration-underline d-flex justify-content-center align-items-center mx-auto" *ngIf="!walletAddress">
                <div   class="pt-2 m-2 w-100  aniBtn m-0 cursor-pointer">
                  <span class="d-inline-block text-black fw-semibold font-14 d-flex justify-content-center align-items-center text-decoration-underline" translate>widget.no_wallet_click_here</span>
                </div>
              </a>
              <!-- (click)="openBestWallet(bestwallet)" -->
              <div  class="m-2 pt-2 d-none  p-0 m-0  w-100 d-md-flex justify-content-center align-items-center aniBtn cursor-pointer" (click)="connectModal(true)" *ngIf="!walletAddress">
                 <span class="d-inline-block  text-black fw-semibold d-flex justify-content-center font-14 text-decoration-underline" translate>widget.no_wallet_click_here</span>
              </div>
              <!--- dont have wallet-->

              <p class="font-14 text-center fw-regular mb-0" translate>widget.powered_by
                <a href="https://web3paymentsolutions.io" target="_blank">
                  <img src="/assets/images/svg-icons/W3P_Black.svg" alt="" class="poweredByIcon text-tertiary">
                </a>
              </p>
            </div>

          </div>
        </div>

      </div>
      
      <img src="/assets/images/banner-bottom.webp" class="img-fluid banner-bottom d-none d-lg-block" alt="hero-image" />
      <img src="/assets/images/banner-bottom.webp" class="img-fluid mx-auto d-block d-lg-none banner-bottom" alt="bottom-img">
    </div>
  </div>
