<section class="news" id="news">
  <div class="text-center">
    <h2 class="section-title">
      <span class="text-secondary me-2" translate>news.title_1</span>
      <span class="text-white" translate>news.title_2</span>
    </h2>
  </div>

  <div
    class="d-none d-md-flex align-items-center justify-content-center news-container container mt-5"
  >
    <div class="news-card">
      <div class="image-container"></div>
      <div class="mt-3">
        <div class="d-flex align-items-center gap-2 mb-3">
          <svg-icon
            src="/assets/images/svg-icons/news-calender.svg"
            [svgStyle]="{ 'width.px': '13', 'height.px': '13' }"
          ></svg-icon>
          <p class="date font-14 text-grey mb-0 mt-1" translate>news.card_1_date</p>
        </div>
        <p class="date font-15 fw-semibold" translate>
          news.card_1_title
        </p>
      </div>
    </div>
    <div class="news-card">
      <div class="image-container"></div>
      <div class="mt-3">
        <div class="d-flex align-items-center gap-2 mb-3">
          <svg-icon
            src="/assets/images/svg-icons/news-calender.svg"
            [svgStyle]="{ 'width.px': '13', 'height.px': '13' }"
          ></svg-icon>
          <p class="date font-14 text-grey mb-0 mt-1" translate>news.card_1_date</p>
        </div>
        <p class="date font-15 fw-semibold" translate>
          news.card_1_title
        </p>
      </div>
    </div>
    <div class="news-card">
      <div class="image-container"></div>
      <div class="mt-3">
        <div class="d-flex align-items-center gap-2 mb-3">
          <svg-icon
            src="/assets/images/svg-icons/news-calender.svg"
            [svgStyle]="{ 'width.px': '13', 'height.px': '13' }"
          ></svg-icon>
          <p class="date font-14 text-grey mb-0 mt-1" translate>news.card_1_date</p>
        </div>
        <p class="date font-15 fw-semibold" translate>
          news.card_1_title
        </p>
      </div>
    </div>
    <div class="news-card">
      <div class="image-container"></div>
      <div class="mt-3">
        <div class="d-flex align-items-center gap-2 mb-3">
          <svg-icon
            src="/assets/images/svg-icons/news-calender.svg"
            [svgStyle]="{ 'width.px': '13', 'height.px': '13' }"
          ></svg-icon>
          <p class="date font-14 text-grey mb-0 mt-1" translate>news.card_1_date</p>
        </div>
        <p class="date font-15 fw-semibold" translate>
          news.card_1_title
        </p>
      </div>
    </div>
  </div>

  <div class="d-flex d-md-none news-container">
    <owl-carousel-o [options]="slideOption" class="w-100">
      <ng-template carouselSlide class="my-5">
        <div class="news-card">
          <div class="image-container"></div>
          <div class="mt-3">
            <div class="d-flex align-items-center gap-2 mb-3">
              <svg-icon
                src="/assets/images/svg-icons/news-calender.svg"
                [svgStyle]="{ 'width.px': '13', 'height.px': '13' }"
              ></svg-icon>
              <p class="date font-14 text-grey mb-0 mt-1" translate>news.card_1_date</p>
            </div>
            <p class="date font-15 fw-semibold" translate>
              news.card_1_title
            </p>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide class="my-5">
        <div class="news-card">
          <div class="image-container"></div>
          <div class="mt-3">
            <div class="d-flex align-items-center gap-2 mb-3">
              <svg-icon
                src="/assets/images/svg-icons/news-calender.svg"
                [svgStyle]="{ 'width.px': '13', 'height.px': '13' }"
              ></svg-icon>
              <p class="date font-14 text-grey mb-0 mt-1" translate>news.card_1_date</p>
            </div>
            <p class="date font-15 fw-semibold" translate>
              news.card_1_title
            </p>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide class="my-5">
        <div class="news-card">
          <div class="image-container"></div>
          <div class="mt-3">
            <div class="d-flex align-items-center gap-2 mb-3">
              <svg-icon
                src="/assets/images/svg-icons/news-calender.svg"
                [svgStyle]="{ 'width.px': '13', 'height.px': '13' }"
              ></svg-icon>
              <p class="date font-14 text-grey mb-0 mt-1" translate>news.card_1_date</p>
            </div>
            <p class="date font-15 fw-semibold" translate>
              news.card_1_title
            </p>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="news-card">
          <div class="image-container"></div>
          <div class="mt-3">
            <div class="d-flex align-items-center gap-2 mb-3">
              <svg-icon
                src="/assets/images/svg-icons/news-calender.svg"
                [svgStyle]="{ 'width.px': '13', 'height.px': '13' }"
              ></svg-icon>
              <p class="date font-14 text-grey mb-0 mt-1" translate>news.card_1_date</p>
            </div>
            <p class="date font-15 fw-semibold" translate>
              news.card_1_title
            </p>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
  <img src="/assets/images/svg-icons/grey-bottom-border.svg" class="footer-chain w-100 d-block" alt="border">
</section>
