import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { verifyLanguage } from '../../shared/constants/app-enums';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WINDOW } from '../../services/window';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})

export class HomeComponent {

  lang: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(WINDOW) private window: Window,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {
    if(this.window && isPlatformBrowser(this.platformId)){
      const userLang = navigator?.language?.split('-')[0];
      this.lang = userLang && verifyLanguage(userLang) ? userLang : 'en';
    } else this.lang = 'en';

    this.translate.setDefaultLang('en');
    this.translate.use(this.lang);
  }
}

