<!-- -------- desktop header --------- -->
<div class="header" [ngClass]="{'scrolled': isScrolled}" *ngIf="!isMobile as boolean">

  <div class="d-flex justify-content-between align-items-center w-100 menu-header">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center w-100">

        <a class="cursor-pointer d-flex align-items-center p-0" routerLink="/{{currentLanguage}}/" #navigation (click)="addActive($event, '')">
          <img src="./assets/images/svg-icons/logo.png" height="53" width="53"/>
        </a>

        <div class="menu-item d-flex align-items-center">
          <div class="list">
            <a routerLink="/{{currentLanguage}}/dashboard" (click)="addActive($event,'')" title="staking"
              translate>top_navigation.nav_dashboard</a>

            <a class="mx-2" routerLink="/{{currentLanguage}}" fragment="{{item.routeName}}"
              (click)="addActive($event, item.routeName)" title="{{item.routeName}}" translate
              *ngFor="let item of navigations">{{item.navName}}
            </a>
          </div>

        </div>


        <div class="menu-item d-flex align-items-center">
          <a class="top-btn  font-16 fw-bold" target="_blank" href="#" translate><span class="ps-2" translate>misc.whitepaper</span></a>

          <!--- buynow wallet/address --->

          <!-- <div class="top-btn btn-white" (click)="showWidget('home')" *ngIf="!walletAddress" translate>widget.buy_now</div>
          <div class="top-btn btn-white ms-4" (click)="disconnect()" *ngIf="walletAddress">{{walletAddress | trimAddress}}</div> -->


          <!---language dropdown-->

          <div class="btn-group language mx-2" dropdown>
            <div dropdownToggle type="button" class="dropdown-toggle amountType d-flex align-items-center">
              <div class="font-12 text-uppercase fw-semibold">{{ selectedLanguage?.value }}</div>
              <!-- <img class="flag-icon mx-1" src="./assets/images/flags/{{selectedLanguage?.flag}}.svg" alt="" loading="lazy"> -->
              <svg-icon class="dropdown ms-2" src="./assets/images/svg-icons/angle-down.svg"
                [svgStyle]="{ 'width.px':'14', 'height.px':'22' }"></svg-icon>
            </div>

            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
              <li class="p-2 cursor-pointer" role="menuitem" *ngFor="let lang of languagesList"
                (click)="changeLanguage(lang.value)">
                <img src="./assets/images/flags/{{lang.flag}}.svg" alt="" loading="lazy">
                <span>{{ lang.name }}</span>
              </li>
            </ul>
          </div>


          <!--- social links --->
          <div class="d-flex align-items-center justify-content-center social-wrapper">
            <div class="d-flex align-items-center justify-content-center social-wrapper">
              <a *ngFor="let item of social" href="{{item.link}}" class="mx-1 p-0" target="_blank">
                <img src="{{item.img}}" alt="{{item.alt}}" loading="lazy">
              </a>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>

<!-- -------- mobile header --------- -->

<div class="header" [ngClass]="{'scrolled': isScrolled}" *ngIf="isMobile as boolean">

  <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">

    <div class="d-flex justify-content-start align-items-center">
      <a class="cursor-pointer p-0" routerLink="/{{currentLanguage}}/" #navigation>
        <img src="./assets/images/svg-icons/logo.png" height="50" width="50"/>
      </a>
    </div>

    <div class="menu-btn" (click)="openCloseMenu()">
      <svg-icon src="./assets/images/svg-icons/{{showMenu ? 'close-white' : 'burger-icon'}}.svg"
        [svgStyle]="{ 'width.px':40, 'height.px':40 }"></svg-icon>
    </div>

  </div>

  <div class="menu-overlay" *ngIf="showMenu">
    <div class="sub-menu pull-left" [ngClass]="{'open': openMenu}">

      <!--- menu links-->
      <div class="d-flex flex-column justify-content-start align-items-start w-100">
        <!-- <a class="font-14 py-2 pb-2" routerLink="/{{currentLanguage}}/dashboard" title="staking"
          (click)="openCloseMenu()" routerLinkActive="active" translate>top_navigation.nav_dashboard</a> -->
        <a class="mx-0 pb-1 text-whitre" *ngFor="let item of navigations" routerLink="/{{currentLanguage}}"
          fragment="{{item.routeName}}" (click)="addActive($event, item.routeName); openCloseMenu()"
          title="{{item.routeName}}" translate>{{item.navName}}
        </a>
      </div>

      <!--- social links --->

      <div class="d-flex align-items-center justify-content-center social-wrapper my-3">
        <a *ngFor="let item of social" href="{{item.link}}" class="link m-0" target="_blank">
          <img src="{{item.img}}" alt="{{item.alt}}" loading="lazy">
        </a>
      </div>

      <!---language dropdown-->
      <div class="btn-group language my-3" dropdown>
        <div dropdownToggle type="button"
          class="dropdown-toggle amountType d-flex align-items-center justify-content-between">
          <div>
            <img class="flag-icon" src="./assets/images/flags/{{selectedLanguage?.flag}}.svg" alt="" loading="lazy">
            <!-- <span class="ms-1 font-11 text-uppercase fw-regular">{{ selectedLanguage?.country }}</span> -->
            <span class="ms-1 font-16 text-uppercase fw-semi-bold">{{ selectedLanguage?.value }}</span>
          </div>

          <svg-icon class="dropdown" src="./assets/images/svg-icons/down-arrow.svg"
            [svgStyle]="{ 'width.px':'20', 'height.px':'20' }"></svg-icon>
        </div>

        <ul *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
          <li class="p-2 cursor-pointer" role="menuitem" *ngFor="let lang of languagesList"
            (click)="changeLanguage(lang.value); openCloseMenu()">
            <img src="./assets/images/flags/{{lang.flag}}.svg" alt="" loading="lazy">
            <span>{{ lang.name }}</span>
          </li>
        </ul>
      </div>

      <div class="btn btn-secondary mt-4 mx-auto" (click)="showWidget('home'); openCloseMenu()" *ngIf="!walletAddress"
        translate>widget.buy_now</div>
      <div class="btn btn-secondary mt-4 mx-auto" (click)="disconnect(); openCloseMenu()" *ngIf="walletAddress">{{
        walletAddress | trimAddress }}</div>

    </div>

  </div>
</div>