import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})

export class FeatureComponent {

  isMobile:boolean = false;
  windowSize: number = 0;

  slideOption: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    nav: false,
    autoplayTimeout: 2000,
    center:true,
    margin:10,
    autoWidth: true,
    responsive: {
      0: {
        items: 1.5,
        // margin:15,
      },
      400: {
        items: 4
      },
      740: {
        items: 6
      },
      940: {
        items: 6
      }

    },
  };

  items: Array<any> = [
    {
      img: './assets/images/featured/coinpedia.svg'
    },
    {
      img: './assets/images/featured/cointelegraph.svg'
    },
    {
      img: './assets/images/featured/cryptonews.svg'
    },
    {
      img: './assets/images/featured/cryptopolitian.svg'
    },
    {
      img: './assets/images/featured/newsbtc.svg'
    },
    {
      img: './assets/images/featured/techopedia.svg'
    },
  ]

  // @HostListener('window:resize', ['$event'])
  // onResize(event: any) {
  //   this.windowSize = window.innerWidth;
  //   this.isMobile = this.windowSize <= 1024 ? true : false;
  // }

  // constructor(
  //   @Inject(PLATFORM_ID) private _platformId: Object,
  // ) {
  // }

  // ngAfterViewInit(): void {
  //   if (isPlatformBrowser(this._platformId)) {
  //     this.windowSize = window.innerWidth;
  //     this.isMobile = this.windowSize <= 1024 ? true : false;
  //   }
  // }
}
