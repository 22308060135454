import { Component } from '@angular/core'
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

  items: any = [
    {
      heading: 'faq.faq_1',
      content: 'faq.faq_cont_1',
    },
    {
      heading: 'faq.faq_2',
      content: 'faq.faq_cont_2',
    },
    {
      heading: 'faq.faq_3',
      content: 'faq.faq_cont_3',
    },
    {
      heading: 'faq.faq_4',
      content: 'faq.faq_cont_4',
    },
  ]
}
