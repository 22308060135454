import { ApplicationRef, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { connect, readContracts, switchChain, watchAccount, simulateContract, writeContract, disconnect, reconnect, getAccount, getBalance, waitForTransactionReceipt, WriteContractErrorType, WriteContractReturnType, watchConnections, getConnectors } from '@wagmi/core'
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { encodeFunctionData, formatEther, parseEther, formatUnits } from 'viem';


import { PopupService } from './popup.service';
import { coinbaseConnector, config, metaMaskConnector, uriConnector, walletConnector, walletConnectorBW } from "./config";
import { EventService } from './event.service';
import { SwapStatus, getBrowserName, getParam, getParamWithoutCookie } from '../shared/constants/app-enums';
import { environment } from "../../environments/environment";
import { WINDOW } from "./window";
import { isPlatformBrowser } from "@angular/common";


/**
 * variable used for the presale smart contract
 * of different network
 */

const staking = require('../../assets/contracts/staking.json');
const token = require('../../assets/contracts/token.json');

const presaleEth = require('../../assets/contracts/presaleETH.json');
const presaleBsc = require('../../assets/contracts/presaleBSC.json');

const eth_usdt_token = require('../../assets/contracts/eth_usdt_token.json');
const bsc_usdt_token = require('../../assets/contracts/bsc_usdt_token.json');

/**
 * here the presale ABI and Address are set
 * for read, write and prepair contract
 */
const stakeContract = {
  address: environment.stakingAddress as any,
  abi: staking.abi,
} as const

const presaleContractEth = {
  address: environment.eth.presaleAddress as any,
  abi: presaleEth.abi,
} as const

const presaleContractBsc = {
  address: environment.bsc.presaleAddress as any,
  abi: presaleBsc.abi,
} as const

const ethUsdtContract = {
  address: environment.eth.usdTAddress as any,
  abi: eth_usdt_token.abi,
} as const

const bscUsdtContract = {
  address: environment.bsc.usdTAddress as any,
  abi: bsc_usdt_token.abi,
} as const

const tokenContract = {
  address: environment.tokenAddress as any,
  abi: token.abi,
} as const


@Injectable({
  providedIn: 'root'
})

export class WalletConnectService {

  swapStatus = SwapStatus.not_started;
  walletAddress: string = '';
  oldWalletAddress: string = '';
  referUrl: string = '';
  client: any;
  wagmiData: any;
  chainId: any;
  mode: any;

  presaleData: any;
  swapHash: any = '';
  refreshId: any;
  refreshRate: number = 5000;

  /* variables used for Wallet connection */

  metaMaskConnector: any;
  walletConnector: any;
  walletConnectorBW: any;
  uriConnector: any;

  /* predefined values of the essential variables */

  balanceData: {
    nativeBal: number,
    usdtBal: number,
    ethTokenSold: number,
    bscTokenSold: number,
    polyTokenSold: number,
    totalTokensSold: number,
    ethClaimable?: number,
    polyClaimable?: number,
    bscClaimable?: number,
    userClaimable: number,
    currentStep: number,
    startTime?: any,
    endTime?: any,
    paused?: boolean,
    usdRaised?: number,
    maxTokensToBuy?: number,
    oneEth: number,
    oneBnb: number,
    onePoly: number,
    ethUsdt: number,
    bnbUsdt: number,
    polyUsdt: number
  } = {
      nativeBal: 0,
      usdtBal: 0,
      ethTokenSold: 0,
      bscTokenSold: 0,
      totalTokensSold: 0,
      ethClaimable: 0,
      bscClaimable: 0,
      userClaimable: 0,
      polyTokenSold: 0,
      polyClaimable: 0,
      startTime: '',
      endTime: '',
      paused: false,
      currentStep: 0,
      usdRaised: 0,
      maxTokensToBuy: 0,
      oneEth: 0,
      oneBnb: 0,
      ethUsdt: 0,
      bnbUsdt: 0,
      polyUsdt: 0,
      onePoly: 0
    }

  stakeData: {
    planId: number;
    userStaked: number,
    totalStaked: number,
    rewardPerRound: number,
    userPoolPercent: number,
    apy: number,
    unlockTime: number,
    userReward: number,
    userBal: number,
    isClaimEnabled: boolean
  } = {
      userStaked: 0,
      totalStaked: 0,
      rewardPerRound: 0,
      userPoolPercent: 0,
      apy: 0,
      unlockTime: 0,
      userReward: 0,
      userBal: 0,
      isClaimEnabled: false,
      planId: 0
    }

  /**
   * Tokenomics data is defining the no of preslae stages,
   * price of each token, minimum and maximum no of token
   * for each stages. It is also defining the end date of each presale stages.
   */

  tokenomics = [
    {
      minToken: 0,
      maxToken: 588000000,    //1
      maxAmount: 382200,
      tokenUSDT: 0.00065000,
      title: 'widget.round_title',
      endDate: 1723456800,
    },
    {
      minToken: 588000000,
      maxToken: 1176000000,    //2
      maxAmount: 766164,
      tokenUSDT: 0.00065300,
      title: 'widget.round_title',
      endDate: 1723888800,
    },
    {
      minToken: 1176000000,
      maxToken: 1764000000,    //3
      maxAmount: 1151892,
      tokenUSDT: 0.00065600,
      title: 'widget.round_title',
      endDate: 1724320800,
    },
    {
      minToken: 1764000000,
      maxToken: 2352000000,    //4
      maxAmount: 1539384,
      tokenUSDT: 0.00065900,
      title: 'widget.round_title',
      endDate: 1724752800,
    },
    {
      minToken: 2352000000,
      maxToken: 2940000000,    //5
      maxAmount: 1928640,
      tokenUSDT: 0.00066200,
      title: 'widget.round_title',
      endDate: 1725184800,
    },
    {
      minToken: 2940000000,
      maxToken: 3528000000,    //6
      maxAmount: 2319660,
      tokenUSDT: 0.00066500,
      title: 'widget.round_title',
      endDate: 1725616800,
    },
    {
      minToken: 3528000000,
      maxToken: 4116000000,    //7
      maxAmount: 2712444,
      tokenUSDT: 0.00066800,
      title: 'widget.round_title',
      endDate: 1726048800,
    },
    {
      minToken: 4116000000,
      maxToken: 4704000000,    //8
      maxAmount: 3106992,
      tokenUSDT: 0.00067100,
      title: 'widget.round_title',
      endDate: 1726480800,
    },
    {
      minToken: 4704000000,
      maxToken: 5292000000,    //9
      maxAmount: 3503304,
      tokenUSDT: 0.00067400,
      title: 'widget.round_title',
      endDate: 1726912800,
    },
    {
      minToken: 5292000000,
      maxToken: 5880000000,   //10
      maxAmount: 3901380,
      tokenUSDT: 0.00067700,
      title: 'widget.round_title',
      endDate: 1727344800,
    },
    {
      minToken: 5880000000,
      maxToken: 6457500000,   //11
      maxAmount: 4294080,
      tokenUSDT: 0.00068000,
      title: 'widget.round_title',
      endDate: 1727776800,
    },
    {
      minToken: 6457500000,
      maxToken: 7035000000,   //12
      maxAmount: 4688513,
      tokenUSDT: 0.00068300,
      title: 'widget.round_title',
      endDate: 1728208800,
    },
    {
      minToken: 7035000000,
      maxToken: 7612500000,   //13
      maxAmount: 5084678,
      tokenUSDT: 0.00068600,
      title: 'widget.round_title',
      endDate: 1728640800,
    },
    {
      minToken: 7612500000,
      maxToken: 8190000000,   //14
      maxAmount: 5482575,
      tokenUSDT: 0.00068900,
      title: 'widget.round_title',
      endDate: 1729072800,
    },
    {
      minToken: 8190000000,
      maxToken: 8767500000,   //15
      maxAmount: 5882205,
      tokenUSDT: 0.00069200,
      title: 'widget.round_title',
      endDate: 1729504800,
    },
    {
      minToken: 8767500000,
      maxToken: 9345000000,   //16
      maxAmount: 6283568,
      tokenUSDT: 0.00069500,
      title: 'widget.round_title',
      endDate: 1729936800,
    },
    {
      minToken: 9345000000,
      maxToken: 9922500000,   //17
      maxAmount: 6686663,
      tokenUSDT: 0.00069800,
      title: 'widget.round_title',
      endDate: 1730368800,
    },
    {
      minToken: 9922500000,
      maxToken: 10500000000,   //18
      maxAmount: 7091490,
      tokenUSDT: 0.00070100,
      title: 'widget.round_title',
      endDate: 1730800800,
    },
  ];

  tokenomics2: any;
  chains: any;
  config: any;
  isBW: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(WINDOW) private window: Window,
    private http: HttpClient,
    private appRef: ApplicationRef,
    private eventService: EventService,
    private popupService: PopupService,
    private transletService: TranslateService,
  ) {
    if (window && isPlatformBrowser(this._platformId)){

      let tempVar = JSON.stringify(this.tokenomics);
      this.tokenomics2 = JSON.parse(tempVar);

      /**
       * wagmi createConfig setup for auto connect feature
       * and available connectors of wagmi.
       */

      this.reconnectMode();

      /**
       * watchAccount function continuously monitors the user’s
       * connected network. Whenever user switched/changed its
       * current network, account. This function keeps tracking that data
       * and updates the current chain id, wallet address accordingly.
       */


      watchAccount(config, {
        onChange: (data) => {
          this.setData();
          // console.log('Account changed!', data)
        },
      })

      watchConnections(config, {
        onChange: (data) => {
          this.setData();
          // console.log('Account changed!', data)
        },
      })
    }
  }


  async reconnectMode() {
    const recentConnectorId = await config.storage?.getItem('recentConnectorId');
    // console.log('\n==== wallet recentConnectorId ====\n', recentConnectorId, '\n====');
    if (recentConnectorId) {
      this.windowDataLayer('connectWallet', 'start', 1, 1, 0, '', '', 0, 0);
      await reconnect(config, {
      }).then((success: any) => {
        // console.log('\n==== wallet reconnected ====\n', success, '\n====');
        this.setData();
      },
        (err: any) => {
          console.log('\n==== wallet reconnection issue ====\n', err, '\n====');
          this.setData();
        })
    } else {
      this.setData();
    }
  }

  /**
   * This function serves as the event listener for WalletConnect (without popup).
   * It should be invoked prior to the connect wallet function to listen for the "display_uri" event.
   * By leveraging this event, users can be directed to a specific BW app for wallet connection.
   */

  getListenWalletConnectEvent() {
    if (typeof window == 'undefined')
      return;
    const connectors = getConnectors(config);
    console.log("connectors", connectors)
    if (connectors.length > 0) {
      connectors.forEach((connector) => {
        connector.getProvider().then((provider: any) => {
          provider?.on('display_uri', (WalletUri: string) => {
            if (typeof window !== 'undefined') {
              const bwUrl = getParamWithoutCookie('bwUrl');
              const link = document.createElement('a');
              const urlParams = WalletUri + '&callbackUrl=' + window.location.href + '&browser=' + getBrowserName();
              link.href = environment.bwDeepLink + urlParams;
              if (bwUrl && bwUrl !== '') {
                link.href = bwUrl + urlParams;
                link.click();
              } else {
                // link.href = environment.bwUniversalLink + '/connect?' + urlParams;
                // link.target = '_blank';
                link.href = environment.bwDeepLink + urlParams;
                link.click();
              }
            }
          });
        });
      });
    }
  }

  /**
   * this is connect wallet function. The mode argument
   * is actually providing a choice to the app user whether
   * they want to connect via Metamask, WalletConnect V2 or web3Auth.
   * This function is mainly called from connect modal component.
   */



  async connectWallet(mode: string, isBsNetwork: boolean = false) {
    this.mode = mode;
    this.windowDataLayer('connectWallet', 'start', 1, 1, 0, '', '', 0, 0);
    if (mode === 'bw') {
      this.getListenWalletConnectEvent();
    }
    await connect(config, {
      chainId: isBsNetwork ? environment.bsc.chainIdInt : environment.eth.chainIdInt,
      connector: mode === 'metamask' ? metaMaskConnector
      : mode === 'wallet' ? walletConnector
      : mode === 'walletBW' ? walletConnectorBW
      : mode === 'bw'  ? uriConnector
      : mode === 'coinbase' ? coinbaseConnector
      : this.walletConnector,
    }).then((success: any) => {
      console.log('\n==== wallet connected ====\n', success, '\n====');
      this.setData();
    },
      (err: any) => {
        console.log('\n==== wallet connection issue ====\n', err, '\n====');
      })
  }


  /**
   * this is disconnect wallet function. After a successful disconnection
   * a developer can reset the different variables values.
   */

  async disConnectWallet() {
    await disconnect(config)
      .then((success: any) => {
        console.log('\n==== wallet disconnected ====\n');
        this.setData();
        this.referUrl = '';
        this.balanceData.nativeBal = 0;
        this.balanceData.usdtBal = 0;
      },
        (err: any) => {
          console.log('\n==== wallet disconnect issue ====\n');
          console.log('disconnect issue =', err);
        })
  }


  /**
   * This function is getting called from watchAAccount.
   * Here we are saving the wagmi wallet address and
   * also calling the getPresale function for re-read
   * the smart contract function with new wallet address
   */

  setData(): void {
    this.wagmiData = getAccount(config);
    this.chainId = this.wagmiData.chainId;
    this.walletAddress = this.wagmiData.address ? this.wagmiData.address.toLowerCase() : '';
    this.refreshRate = this.wagmiData.address ? 10000 : 20000;
    this.eventService.isConnected = this.wagmiData.isConnected;
    // this.appRef.tick();
    this.getPresalesData();
    setTimeout(() => {
      this.eventService.setNetwork();
      if (this.wagmiData.address && this.wagmiData.address != this.oldWalletAddress) {
        this.oldWalletAddress = this.wagmiData.address
        this.sendDashFx('', 0, 0, true);
        this.windowDataLayer('connectWallet', 'successful', 2, 1, 0, '', '', 0, 0);
      }
    }, 2000);
  }


  /**
   * In thiss fnctiion we are calling the different method
   * of smart contract to get different values
   */

  async getPresalesData() {
    
      const smartContract = {
        contracts: [
          // =========== Staking Contract ==============
          {
            ...stakeContract,
            functionName: 'poolStakers',
            chainId: environment.eth.chainIdInt, // 0
            args: [this.walletAddress || "0x0000000000000000000000000000000000000000"]
          },
          {
            ...stakeContract,
            functionName: 'getRewards',
            chainId: environment.eth.chainIdInt, // 1
            args: [this.walletAddress || "0x0000000000000000000000000000000000000000"]
          },
          {
            ...stakeContract,
            functionName: 'lockedTime',
            chainId: environment.eth.chainIdInt, // 2
            args: []
          },
          {
            ...stakeContract,
            functionName: 'rewardTokensPerBlock',
            chainId: environment.eth.chainIdInt, // 3
            args: []
          },
          {
            ...stakeContract,
            functionName: 'tokensStaked',
            chainId: environment.eth.chainIdInt, // 4
            args: []
          },
          {
            ...stakeContract,
            functionName: 'harvestLock',
            chainId: environment.eth.chainIdInt, // 5
            args: []
          },

          // =========== ETH Contract ==============
          {
            ...presaleContractEth,
            functionName: 'totalTokensSold',      // this method is returning the no of tokens sold via BSC
            chainId: environment.eth.chainIdInt, // 6
            args: []
          },
          {
            ...presaleContractEth,
            functionName: 'ethBuyHelper',         // this method is returning the amount of bnb required for 1 app token.
            chainId: environment.eth.chainIdInt,  // 7
            args: [1]
          },
          {
            ...presaleContractEth,
            functionName: 'usdtBuyHelper',        // this method is returning the amount of usdt required for 1 app token.
            chainId: environment.eth.chainIdInt,  // 8
            args: [1]
          },
          {
            ...presaleContractEth,
            functionName: 'usdRaised',            // this method is returning the total USDT raised via BSC token.
            chainId: environment.eth.chainIdInt,  // 9
            args: []
          },
          {
            ...presaleContractEth,               // 10 MAx token allowed to buy
            functionName: 'maxTokensToBuy',
            chainId: environment.eth.chainIdInt,
            args: []
          },

          // =========== BSC Contract ==============

          {
            ...presaleContractBsc,
            functionName: 'totalTokensSold',     // this method is returning the no of tokens sold via BSC
            chainId: environment.bsc.chainIdInt, // 11
            args: []
          },
          {
            ...presaleContractBsc,
            functionName: 'bnbBuyHelper',         // this method is returning the amount of bnb required for 1 app token.
            chainId: environment.bsc.chainIdInt, // 12
            args: [1]
          },
          {
            ...presaleContractBsc,
            functionName: 'usdtBuyHelper',        // this method is returning the amount of usdt required for 1 app token.
            chainId: environment.bsc.chainIdInt, // 13
            args: [1]
          },
          {
            ...presaleContractBsc,
            functionName: 'usdRaised',           // this method is returning the total USDT raised via BSC token.
            chainId: environment.bsc.chainIdInt, // 14
            args: []
          },
          {
            ...presaleContractEth,
            functionName: 'currentStep',
            chainId: environment.eth.chainIdInt,  //15
            args: []
          },
          {
            ...presaleContractEth,
            functionName: 'roundDetails',
            chainId: environment.eth.chainIdInt,  //16
            args: [2]
          },
          {
            ...presaleContractEth,                // 17
            functionName: 'trackRemainingTokens',
            chainId: environment.eth.chainIdInt,
            args: []
          },
          {
            ...presaleContractBsc,                // 18
            functionName: 'trackRemainingTokens',
            chainId: environment.bsc.chainIdInt,
            args: []
          },
          {
            ...presaleContractBsc,
            functionName: 'userDeposits',         // this method is returning the total token purchased by current user via BSC network.
            chainId: environment.bsc.chainIdInt,  // 19
            args: [this.walletAddress || "0x0000000000000000000000000000000000000000"]
          },
          {
            ...presaleContractEth,
            functionName: 'userDeposits',         // this method is returning the total token purchased by current user via ETH network.
            chainId: environment.eth.chainIdInt,  // 20
            args: [this.walletAddress || "0x0000000000000000000000000000000000000000"]
          },
        ]
      } as const

      /**
       * Above contracts are going to be read.
       */

      this.presaleData = await readContracts(config, smartContract);

      // console.log('\n******presaleData =', this.presaleData, '\n******\n');


      this.balanceData.currentStep = Number(this.presaleData[15].result);
      this.stakeData.userStaked = +formatEther(this.presaleData[0].result[0] || 0);
      this.stakeData.unlockTime = Number(this.presaleData[0].result[1]) + Number(this.presaleData[2].result);
      this.stakeData.userReward = +formatEther(this.presaleData[1]?.result || 0);
      this.stakeData.rewardPerRound = +formatEther(this.presaleData[3].result || 0);
      this.stakeData.totalStaked = +formatEther(this.presaleData[4].result || 0);
      this.stakeData.isClaimEnabled = !this.presaleData[5].result || false;
      this.stakeData.apy = +((+environment.apyCalcConst / this.stakeData.totalStaked) * 100).toFixed(0);



      /* assigning the value of no of token sold va bsc and eth contract */
      this.balanceData.ethTokenSold = Number(this.presaleData[6].result);
      this.balanceData.bscTokenSold = Number(this.presaleData[11].result);
      this.balanceData.totalTokensSold = this.balanceData.ethTokenSold + this.balanceData.bscTokenSold;

      /* assigning the value of total USDT raised via eth bsc and poly */

      this.balanceData.oneEth = +formatEther(this.presaleData[7].result);      // no of app tokens in exchange of 1 eth
      this.balanceData.ethUsdt = Number(this.presaleData[8].result);           // no of app tokens in exchange of 1 Eth USDT
      this.balanceData.oneBnb = +formatEther(this.presaleData[12].result);     // no of app tokens in exchange of 1 eth
      this.balanceData.bnbUsdt = Number(this.presaleData[13].result);           // no of app tokens in exchange of 1 Eth USDT
      this.balanceData.usdRaised = +formatEther(this.presaleData[9].result || 0) + +formatEther(this.presaleData[14].result || 0); // + +formatEther(this.presaleData[24].result || 0);


      /* assigning the value of total token purchased by the current user */
      this.balanceData.ethClaimable = +formatEther(this.presaleData[20].result || 0)
      this.balanceData.bscClaimable = +formatEther(this.presaleData[19].result || 0)
      this.balanceData.userClaimable = this.balanceData.ethClaimable + this.balanceData.bscClaimable;

      /**
       * Unsold token adjustment section.
       * if there is any mismatched from the block
       * chain end
       */

      const skipIndexEth: Array<any> = [];
      const skipIndexBsc: Array<any> = [];

      const ethUnsoldArr = this.skipIndexUpdate(this.presaleData[17].result, skipIndexEth);
      // ethUnsoldArr.splice(<index>, 0, <new value>);
      const bnbUnsoldArr = this.skipIndexUpdate(this.presaleData[18].result, skipIndexBsc);

      let counter = 0;
      let unsoldAmt = 0;

      for (let time of this.presaleData[16].result) {
        let roundInfo = this.tokenomics[counter];
        this.tokenomics[counter].endDate = Number(time) * 1000;

        //TODO: revisit this on round 3
        const ethVal = ethUnsoldArr[counter] || 0;
        const bscVal = bnbUnsoldArr[counter] || 0;

        if (counter < this.balanceData.currentStep) {
          let roundUnSold = (1 * (roundInfo?.maxToken - roundInfo?.minToken)) - (ethVal + bscVal);
          roundUnSold = roundUnSold * roundInfo.tokenUSDT;
          unsoldAmt = unsoldAmt + roundUnSold;
          this.tokenomics[counter].maxAmount = Math.ceil(this.tokenomics2[counter].maxAmount + unsoldAmt);
          this.tokenomics[counter + 1].maxAmount = Math.ceil(this.tokenomics2[counter + 1].maxAmount + unsoldAmt);
        }
        counter++;
      }

      const tokenBuyLimit = Number(this.presaleData[10].result);
      const availableToken = this.tokenomics[this.balanceData.currentStep].maxToken - this.soldToken;
      this.balanceData.maxTokensToBuy = availableToken > tokenBuyLimit ? tokenBuyLimit : availableToken;

      // console.log('tokenBuyLimit', tokenBuyLimit, this.tokenomics[this.balanceData.currentStep].maxToken, this.soldToken, this.balanceData.maxTokensToBuy )

      // console.log('\n******\n RPC balanceData =', this.balanceData, '\n******\n');
      // console.log('\n******\n RPC tokenomicsData =', this.tokenomics, '\n******\n');


      if (this.wagmiData?.isConnected) this.getMetaData(); // if user is connected then call this function
      if (this.refreshId) clearTimeout(this.refreshId);
      this.refreshId = setTimeout(() => this.getPresalesData(), this.refreshRate); // Call the self method on each 5secs interval
  }


  // afterNextRender(){
  //   if (this.refreshId) clearTimeout(this.refreshId);
  //   this.refreshId = setTimeout(() => this.getPresalesData(), this.refreshRate); // Call the self method on each 5secs interval
  // }


  /**
   * method to adjust unsold token
   * @param arr the array that need to be adjusted
   * @param skipIndex index no that need to be ignored or skipped
   * @returns a fresh set of array after all adjustment
   */

  skipIndexUpdate(arr: Array<any>, skipIndex: Array<any>): Array<any> {
    let newArr: any = [];
    for (let i = 0; i < arr.length; i++) {
      if (!skipIndex.includes(i)) {
        newArr.push(Number(arr[i]));
      }
    }
    return newArr;
  }


  /**
   * This method is used to fetch the native balance
   * of the current account and network
   */

  async getMetaData() {

    const nativeBalance = await getBalance(config, {
      address: this.walletAddress as any,
    })

    const usdtBal = await getBalance(config, {
      address: this.walletAddress as any,
      token: this.getChainData().usdTAddress as any,
      chainId: this.getChainData().chainIdInt
    })

    /* user's Eth/Bnb and USDT balance */
    this.balanceData.nativeBal = +formatUnits(nativeBalance.value, nativeBalance.decimals);
    this.balanceData.usdtBal = +formatUnits(usdtBal.value, usdtBal.decimals);

    // if(this.referUrl === '') this.refer();
  }

  /**
   * returning no of total sold tokens
   */
  get soldToken() {
    return this.balanceData.totalTokensSold;
  }


  isEthChain(): boolean {
    return this.chainId === environment.eth.chainIdInt ? true : false;
  }

  isBscChain(): boolean {
    return this.chainId === environment.bsc.chainIdInt ? true : false;
  }

  /**
   * switching the current network. If user is on wrong network
   * and got error on switching then a popup
   * will appear saying to connect correct chain
   */
  async switchNetwork(chainId: number) {
    await switchChain(config, {
      chainId: chainId,
    }).then((success: any) => {
      this.appRef.tick();
    },
      (err: any) => {
        const chain = chainId == environment.eth.chainIdInt ? environment.eth : environment.bsc;
        const chainName = chain.chainInfo.params[0].chainName;
        this.popupService.messagePopup("info", this.transletService.instant('switchNetwork', { chain_name: chainName }));
      });

  }


  /**
   * calculating the available no of token in exchange of inserted Eth amount
   */

  getNativeAmount = async (amount: number) => {
    let singleAmount = 0;
    switch (this.getChainData().chainIdInt) {
      case environment.eth.chainIdInt:
        singleAmount = this.balanceData.oneEth;
        break;

      case environment.bsc.chainIdInt:
        singleAmount = this.balanceData.oneBnb;
        break;

      // case environment.poly.chainIdInt:
      //   singleAmount = this.balanceData.onePoly;
      //   break;

      default:
        singleAmount = this.balanceData.oneEth;
    }
    return Math.floor(+(amount / (+singleAmount)));
  }

  /**
   * calculating the available no of token in exchange of inserted Usdt amount
   */

  getUSDTAmount = async (amount: number) => {
    let singleAmount = 0;
    switch (this.getChainData().chainIdInt) {
      case environment.eth.chainIdInt:
        singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6);
        break;

      case environment.bsc.chainIdInt:
        singleAmount = this.balanceData.bnbUsdt / Math.pow(10, 18);
        break;

      // case environment.poly.chainIdInt:
      //   singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6);
      //   break;

      default:
        singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6);
    }
    return Math.floor(+(amount / (+singleAmount)));
  }


  /**
   * reverse calculation required amount (eth/bnb/usdt)
   * in exchange of inserted token
   * @param amount : no of token
   * @param mode : exchange mode
   * @returns : required amount
   */

  getDynamicAmount = async (amount: number, mode: string) => {
    let singleAmount = 0;
    switch (this.getChainData().chainIdInt) {

      case environment.eth.chainIdInt:
        if (mode === 'getNativeAmount') {
          singleAmount = this.balanceData.oneEth;
        } else {
          singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6)
        }
        break;

      case environment.bsc.chainIdInt:
        if (mode === 'getNativeAmount') {
          singleAmount = this.balanceData.oneBnb;
        } else {
          singleAmount = this.balanceData.bnbUsdt / Math.pow(10, 18)
        }
        break;

      // case environment.poly.chainIdInt:
      //   if (mode === 'getNativeAmount') {
      //     singleAmount = this.balanceData.onePoly;
      //   } else {
      //     singleAmount = this.balanceData.polyUsdt / Math.pow(10, 6)
      //   }
      //   break;

      default:
        if (mode === 'getNativeAmount') {
          singleAmount = this.balanceData.oneEth;
        } else {
          singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6)
        }
        break;
    }
    return +(amount * singleAmount);
  }



  /**
   * this function is swaping the native token with app token
   * @param amount: app token amount
   * @param value: eth token value
   */

  async swapNativeTokens(amount: number, value: number, isBuyStake: boolean = false) {

    // setting swapStatus to not started
    this.swapStatus = SwapStatus.not_started;

    /**
     * preparing a contract for swaping via smart contract
     * method of ethBuyHelper
     */

    const { request } = await simulateContract(config, {
      ...this.getPresaleContract(),
      functionName: this.getChainData().nativeFunction,
      chainId: this.getChainData().chainIdInt,
      args: this.getChainData().hasStaking ? [amount, isBuyStake] : [amount],
      value: parseEther(`${value}`),
      // account:      this.walletAddress as any    // Optional param. Not required!
    })

    console.log('simulateContract request =', request);

    // setting swapStatus to confirmation pending
    this.swapStatus = SwapStatus.confirm_pending;

    /**
     * Writing contract for swap process
     */
    await writeContract(config, request)
      .then((hash: any) => {
        console.log('Eth write Contract success', hash);
        this.swapStatus = SwapStatus.in_progess;
        this.windowDataLayer('swap', 'confirmTransaction', 2, 0, 0);
        // this.sendDashFx(this.getChainData().purchaseToken, amount, value);
        this.checkTransaction(hash, this.getChainData().purchaseToken, amount, value);
      },
        (err: any) => {
          console.log('swapNativeTokens Error =', err)
          if (err.code === 4001) {
            this.swapStatus = SwapStatus.rejected;
          } else {
            this.swapStatus = SwapStatus.failed;
          }
        });
  }


  /**
   * this function is checking allowence
   * amount before swaping the usdt token with app token
   * @param amount: app token amount
   * @param value: usdt token value
   * @param afterAllowance: checking whether allowence is granted or not
   */

  async swapCryptoForUSDT(amount: number, value: number, afterAllowance: boolean = false, isBuyStake: boolean = false) {

    /**
     * preparing token contract with ABI
     */
    const usdtContract = this.getUSDTContract();

    // set presale address based on current network
    const presaleAdd = this.getChainData().presaleAddress as any;


    /**
     * reading allowence contract
     */

    const allowanceData = await readContracts(config, {
      contracts: [
        {
          ...usdtContract,
          functionName: 'allowance',
          chainId: this.chainId,
          args: [this.walletAddress, presaleAdd],
        },
      ],
    })

    //  checking provided allowence value in token contract

    let allowanceValue;
    if (this.getChainData() == environment.bsc) {
      allowanceValue = +formatEther(allowanceData[0].result as any);
    } else {
      allowanceValue = Number(allowanceData[0].result) / Math.pow(10, 6);
    }

    console.log('allowanceValue =', value, allowanceValue);

    /**
     * if inserted usdt value is larger than
     * provided allowence value then it needs further approval.
     * below we are preparing contract for allowence approval
     */

    if (+value > +allowanceValue) {
      const { request } = await simulateContract(config, {
        ...usdtContract,
        chainId: this.chainId,
        functionName: 'approve',
        args: [presaleAdd, (afterAllowance || allowanceValue == 0) ? '100000000000000000000000000' : '0'],
        account: this.walletAddress as any    // Optional param. Not required!
      })

      // setting swapStatus to approval pending
      this.swapStatus = SwapStatus.approval_pending;


      // fetching transaction hash key of writing contract
      const hash = await writeContract(config, request);
      console.log('approve allowence hash key =', hash);

      /**
       * checking the approval transaction status.
       * if success and the allowence is preapproved
       * then it will call the buyUSDT function, else
       * will again go through the starting steps but this time with
       * allowence approval true mode.
       */
      await waitForTransactionReceipt(config, { hash })
        .then((success: any) => {
          console.log('approve Config success', success);
          if (afterAllowance) {
            this.buyUSDT(amount, value, isBuyStake);
          } else {
            this.swapCryptoForUSDT(amount, value, true, isBuyStake);
          }
        },
          (err: any) => {
            console.log('approve Config error', err);
            this.swapStatus = SwapStatus.rejected;
          });
    }

    /**
     * if inserted usdt value is smaller than
     * provided allowence value, then buyUSDT
     * function will be called and doesn't need
     * to check for allowence approval
     */

    else {
      this.buyUSDT(amount, value, isBuyStake);
    }
  }


  /**
   * this function is swaping the usdt token with app token
   * @param amount: app token amount
   * @param value: usdt token value
   * @param afterAllowance: checking whether allowence is granted or not
   */

  async buyUSDT(amount: number, value: number, isBuyStake: boolean = false) {

    /**
     * preparing a contract for swaping via smart contract
     * method of buyWithUSDT
     */
    const contract = this.getPresaleContract();
    await simulateContract(config, {
      ...contract,
      functionName: 'buyWithUSDT',
      chainId: this.chainId,
      args: this.getChainData().hasStaking ? [amount, isBuyStake] : [amount],
      // account: this.walletAddress as any   // Optional param. Not required!
    })
      // if prepare contract is success
      .then(async (request: any) => {

        // setting swapStatus to confirmation pending
        console.log('usdtBuyHelper =', request)
        this.swapStatus = SwapStatus.confirm_pending;

        /**
         * Once confirmed startig write contract
         */
        await writeContract(config, request.request)
          .then((hash: WriteContractReturnType) => {
            console.log('USDT writeContract success', hash);
            this.windowDataLayer('swap', 'confirmTransaction', 2, 0, 0);  // setting windowdatalayer for analytics
            // this.sendDashFx('usdt', amount, value);                       // updating dashFx data
            this.swapStatus = SwapStatus.in_progess;                      // setting swapStatus to inprogress mode
            this.checkTransaction(hash, 'usdt', amount, value);           // checking transaction status
          },
            (error: WriteContractErrorType) => {
              console.log('USDT writeContract error', error);
              this.swapStatus = SwapStatus.rejected;
            })
      },

        /**
         * if there is any error while preparing contract
         * like requesting more than max-token buy limit
         * then a warning popup will appear saying that
         * the max-token limitation
         */
        (err: any) => {
          this.popupService.messagePopup("warning", this.transletService.instant('max_token_exceed', { maxTokensToBuy: this.balanceData.maxTokensToBuy }), this.transletService.instant('max_token_title'));
          this.swapStatus = SwapStatus.rejected;
          console.log(err.message)
        });
  }



  /**
   * this function is check the final
   * status of the current transaction
   * @param haskKey: transaction hash key
   * @param mode: Eth/Bnb/Poly/Usdt transaction
   * @param amount: no of tooken
   * @param value:  amount of eth/bnb/usdt
   */

  async checkTransaction(haskKey: string, mode: string, amount: number, value: number) {
    await waitForTransactionReceipt(config, { hash: haskKey as any })
      .then((response: any) => {
        console.log('checkTansaction success', response);
        this.getMetaData(); // update the current native balance
        this.swapStatus = SwapStatus.complete;    // setting swapStatus to completed
        this.swapHash = response.transactionHash; // assigning succes transaction hash id
        this.sendBWData(mode, amount, value);
        this.windowDataLayer('swap', 'swapSuccessful', 3, 1, 0, mode, environment.tokenName, value, amount);
      },
        (err: any) => {
          console.log('checkTansaction error', err);
          this.getMetaData(); // update the current native balance
          this.swapStatus = SwapStatus.failed; // setting swapStatus to failed
        });
  }



  /**
   * function to update analytics data
   * @param flowName: process name like swap/connectWallet
   * @param stepName: swapSuccessful/confirmTransaction/successful
   * @param stepNo: step no of process
   * @param completeFlag: is omppletedd or not
   * @param errorCode: if any error
   * @param fromCurrency: ETH/BNB/POLY/USDT
   * @param toCurrency : ICO/APP token
   * @param fromVal: ETH/BNB/USDT value
   * @param toVal: App token value
   */
  async windowDataLayer(flowName: string, stepName: string, stepNo: number, completeFlag: number, errorCode: number, fromCurrency: string = '', toCurrency: string = '', fromVal: number = 0, toVal: number = 0) {
    if (typeof window == 'undefined')
      return;
    if (environment.production) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "workflowStep",
        walletAddress: this.walletAddress,
        workflowName: flowName,                                               // "swap",
        workflowStepNumber: stepNo,                                           // 2,
        workflowStepName: stepName,                                           // "confirmTransaction",

        workflowCompleteFlag: completeFlag,
        workflowErrorCode: errorCode,

        transactionId: completeFlag === 0 ? undefined : this.swapHash,
        swapFromValue: completeFlag === 0 ? undefined : fromVal,                // value,
        swapToValue: completeFlag === 0 ? undefined : toVal,                    // amount,
        swapFromCurrency: completeFlag === 0 ? undefined : fromCurrency,        // "ETH/BNB/POLY/USDT",
        swapToCurrency: completeFlag === 0 ? undefined : toCurrency,            // "token name"
        presaleStage: completeFlag === 0 ? undefined : this.balanceData.currentStep,            // current running stage
        stageTokenValue: completeFlag === 0 ? undefined : this.tokenomics[this.balanceData.currentStep].tokenUSDT,  // current running stage's token usdt value
        swapFromValueUsd: completeFlag === 0 ? undefined : await this.getDynamicAmount(toVal, 'getUSDTAmount'),
      });
    }
  }



  /**
   * DashFx is another analytics feature
   * @param type : connect wallet/ETH/BNB/USDT
   * @param toValue: App token value
   * @param fromValue : ETH/BNB/USDT value
   * @param connectMode: whether it is wallet connect or not
   */

  sendDashFx = (type: string, toValue: number, fromValue: number, connectMode: boolean = false) => {
    if (environment.production) {
      const tokens: any = (this.balanceData.userClaimable || 0);
      const data = {
        walletAddress: this.walletAddress,
        iid: environment.iidDashFx,
        event: connectMode ? 'lead_success' : (tokens > toValue ? 'revenue' : 'conversion'),
        purchaseType: connectMode ? null : type,
        purchaseTokens: +toValue,
        purchaseTypeAmount: +fromValue,
        purchaseUsdAmount: type === 'usdt' ? +fromValue : +(+(toValue * this.tokenomics[this.balanceData.currentStep].tokenUSDT).toFixed(2)),
        ipAddress: '',
        clickId: getParam('clickId'),
        source: getParam('source')
      }
      console.log('DashFx data =', tokens, data)
      this.http.post(environment.urlDashFx, data).subscribe((res: any) => {
        console.log('DashFx success response', res);
      },
        (err: any) => {
          console.log('DashFx error response', err);
        });
    }
  }


  /**
   * Refer function to get URL
   * to share with friends
   */


  refer() {
    const data = {
      walletAddress: this.walletAddress,
      iid: environment.iidDashFx,
    };
    this.http.post(environment.referUrl, data).subscribe((res: any) => {
      // console.log('refer success response', res);
      this.referUrl = res.data.url;
    },
      (err: any) => {
        // console.log('refer error response', err);
      });
  }


  /**
   * Function to claim of purchased token by the user.
   * Once the presale stage is over, user can claim his token.
   * We are using eth based claim only. This function is
   * preparing a claim contract with a claim method.
   */

  async claim() {
    console.log('Claim called');
    const { request } = await simulateContract(config, {
      ...presaleContractEth,
      functionName: 'claim',
      chainId: environment.eth.chainIdInt,
      args: [],
      account: this.walletAddress as any // Optional param. Not required!
    })

    await writeContract(config, request)
      .then((success: any) => {
        console.log('Claim success', success);
      },
        (err: any) => {
          console.log('Claim error', err);
        });
  }


  async claimStake() {
    console.log('claimStake called');
    this.swapStatus = SwapStatus.in_progess
    const { request } = await simulateContract(config, {
      ...presaleContractEth,
      functionName: 'claimAndStake',
      chainId: environment.eth.chainIdInt,
      args: [],
      account: this.walletAddress as any // Optional param. Not required!
    })
    await writeContract(config, request)
      .then((hash: any) => {
        console.log('claimStake success', hash);
        this.checkTransaction(hash, 'claimstake', 0, 0);   // checking transaction status
      },
        (err: any) => {
          console.log('claimStake error', err);
          this.swapStatus = SwapStatus.failed
        });
  }


  /**
   * Function to claim and stake the token
   */

  async claimStakeRewards() {
    console.log('claimStakeRewards called');
    const { request } = await simulateContract(config, {
      ...stakeContract,
      functionName: 'harvestRewards',
      chainId: environment.eth.chainIdInt,
      args: [],
      account: this.walletAddress as any // Optional param. Not required!
    })
    await writeContract(config, request)
      .then((hash: any) => {
        console.log('claimStakeRewards success', hash);
      },
        (err: any) => {
          console.log('claimStakeRewards error', err);
        });
  }


  /**
   * Function of extend the staking period.
   */


  async extendStake(planId: any) {
    console.log('extendStake called');

    const { request } = await simulateContract(config, {
      ...stakeContract,
      functionName: 'deposit',
      chainId: environment.eth.chainIdInt,
      args: [0, planId]
    })

    this.swapStatus = SwapStatus.confirm_pending;

    await writeContract(config, request)
      .then((hash: any) => {
        console.log('extendStake success', hash);
        this.swapStatus = SwapStatus.in_progess;
        this.checkTransaction(hash, '', 0, 0);
      },
        (err: any) => {
          console.log('extendStake error', err);
          this.swapStatus = SwapStatus.rejected;
        });
  }



  async withdrawStake() {
    console.log('withdrawStake called');
    const { request } = await simulateContract(config, {
      ...stakeContract,
      functionName: 'withdraw',
      chainId: environment.bsc.chainIdInt,
      args: [],
      account: this.walletAddress as any // Optional param. Not required!
    })
    await writeContract(config, request)
      .then((hash: any) => {
        console.log('withdrawStake success', hash);
      },
        (err: any) => {
          console.log('withdrawStake error', err);
        });
  }

  async createStake(amount: any) {
    const allowanceData = await readContracts(config, {
      contracts: [
        {
          ...tokenContract,
          functionName: 'allowance',
          chainId: this.chainId,
          args: [this.walletAddress, environment.stakingAddress],
        },
      ],
    })
    console.log('createStake allowanceData =', allowanceData);

    const allowanceValue = +formatEther(allowanceData[0].result as any);
    const amountEther = +amount;
    console.log(amountEther, allowanceValue, amount);

    if (amountEther > +allowanceValue) {
      const { request } = await simulateContract(config, {
        ...tokenContract,
        chainId: this.chainId,
        functionName: 'approve',
        args: [environment.stakingAddress, '100000000000000000000000000'],
        account: this.walletAddress as any  // Optional param. Not required!

      })

      console.log('approveConfig =', request)
      this.swapStatus = SwapStatus.approval_pending;

      const hash = await writeContract(config, request);
      console.log('createStake approveConfig hash =', hash);

      await waitForTransactionReceipt(config, { hash })
        .then((success: any) => {
          console.log('approveConfig approve Config success', success);
          this.swapStatus = SwapStatus.confirm_pending;
          this.proceedCreateStake(amount);
        },
          (err: any) => {
            console.log('approve Config error', err);
            this.swapStatus = SwapStatus.rejected;
          });
    } else {
      this.proceedCreateStake(amount);
    }
  }


  async proceedCreateStake(amount: any) {

    const { request } = await simulateContract(config, {
      ...stakeContract,
      functionName: 'deposit',
      chainId: environment.eth.chainIdInt,
      args: [parseEther(amount.toString())]
    })

    this.swapStatus = SwapStatus.confirm_pending;
    await writeContract(config, request)
      .then((hash: any) => {
        console.log('proceedCreateStake success', hash);
        this.swapStatus = SwapStatus.in_progess;
        this.checkTransaction(hash, '', amount, 0);
      },
        (err: any) => {
          console.log('Eth createStake error', err);
          this.swapStatus = SwapStatus.rejected;
        });
  }

  /**
 * generate signature for wert transaction
 * @param amount Amount to spend
 * @returns signature data
 */
  async getABI(amount: number, isStake: boolean = false): Promise<string> {
    const data = encodeFunctionData({
      abi: presaleEth.abi,
      functionName: 'buyWithETHWert',
      args: [this.walletAddress, amount, isStake]
    })
    return data;
  }

  /**
* Function to buy app token via wert widget.
* this function convert the USDT value to app token
* @param amount: Amount to spend
* @returns : converted no of app tokens
*/

  getDynamicAmountWert = async (amount: number) => {
    console.log('getDynamicAmountWert', amount)
    const contractData = await readContracts(config, {
      contracts: [
        {
          ...presaleContractEth,
          functionName: 'ethBuyHelper',
          chainId: environment.eth.chainIdInt,
          args: [amount]
        }
      ]
    });
    let ehtVal = +formatEther(contractData[0].result as any);
    console.log('return DynamicAmountWert', ehtVal)
    return +ehtVal;
  }

  /**
 * BestWallet Data analytics
 * @param type : connect wallet/ETH/BNB/USDT
 * @param toValue: App token value
 * @param fromValue : ETH/BNB/USDT value
 * @param connectMode: whether it is wallet connect or not
 */

  sendBWData = (type: string, toValue: number, fromValue: number) => {
    if (environment.production) {
      const tokens: any = (this.balanceData.ethClaimable || 0);
      const data = {
        publicAddress: this.walletAddress,
        event: (tokens > toValue ? 'revenue' : 'conversion'),
        purchaseType: type,
        purchaseTokens: +toValue,
        purchaseTypeAmount: +fromValue,
        purchaseUsdAmount: type === 'usdt' ? +fromValue : +(+(toValue * this.tokenomics[this.balanceData.currentStep].tokenUSDT).toFixed(2)),
        ipAddress: '',
        clickId: getParam('clickId'),
        source: getParam('source'),
        hash: this.swapHash,
        chainId: this.chainId,
        presaleName: "ICO-Boilerplate",
        isBW: this.isBW,
        rABsZdfEqoXvExie: getParamWithoutCookie('rABsZdfEqoXvExie') || undefined,
      }
      console.log('BW data =', tokens, data)
      this.http.post(environment.bwApiUrl, data).subscribe((res: any) => {
        console.log('BW success response', res);
      },
        (err: any) => {
          console.log('BW error response', err);
        });
    }
  }

  getChainData() {
    switch (this.chainId) {
      case environment.eth.chainIdInt:
        return environment.eth;
      case environment.bsc.chainIdInt:
        return environment.bsc;
      // case environment.poly.chainIdInt:
      //   return environment.poly;
      default:
        return environment.eth;
    }
  }

  getPresaleContract() {
    switch (this.chainId) {
      case environment.eth.chainIdInt:
        return presaleContractEth;
      case environment.bsc.chainIdInt:
        return presaleContractBsc;
      // case environment.poly.chainIdInt:
      //   return presaleContractPoly;
      default:
        return presaleContractEth;
    }
  }

  getUSDTContract() {
    switch (this.chainId) {
      case environment.eth.chainIdInt:
        return ethUsdtContract;
      case environment.bsc.chainIdInt:
        return bscUsdtContract;
      // case environment.poly.chainIdInt:
      //   return polyUsdtContract;
      default:
        return ethUsdtContract;
    }
  }
}
// function prepareWriteContract(arg0: { functionName: string; chainId: number; args: any[]; address: any; abi: any; }): { request: any; } | PromiseLike<{ request: any; }> {
//   throw new Error("Function not implemented.");
// }

