import { Component } from '@angular/core';

@Component({
  selector: 'app-bullish-by-design',
  templateUrl: './bullish-by-design.component.html',
  styleUrls: ['./bullish-by-design.component.scss']
})
export class BullishByDesignComponent {

}
