<section class="sectionGap faq" id="faq">
    <div class="container">     
        <h2 class="section-title mx-auto text-center">
            <span class="text-secondary me-2" translate>faq.title_1</span>
            <span class="text-white" translate>faq.title_2</span>
        </h2> 
        <div class="row d-flex align-items-center justify-content-center">          
            <div class="col-12 col-lg-10">            
                <div class="faq-wrapper pt-4">
                    <accordion [closeOthers]="true" [isAnimated]="true">
                        <accordion-group *ngFor="let item of items;let i = index;">
                            <div class="  position-relative w-100 fw-medium d-flex justify-content-between"
                                accordion-heading>
                                <span class=" text-white font-16 font-sm-18 pe-5">
                                    {{item.heading | translate}}
                                </span>
                                <img src="/assets/images/svg-icons/angle-down.svg" class="icon pt-2" alt="arrow" height="19"/>
                            </div>
                            <div accordion-panel class="mt-0">
                                <span class="para fw-regular"> {{item.content | translate}}</span><br />
                            </div>
                        </accordion-group>
                        <accordion-group>
                            <div class=" position-relative w-100 fw-medium d-flex justify-content-between"
                                accordion-heading>
                                <span class=" text-white font-16 font-sm-18 pe-5">
                                    {{ 'faq.faq_5' | translate}}
                                </span>
                                <img src="/assets/images/svg-icons/angle-down.svg" class="icon pt-2" alt="arrow" height="19" />
                            </div>
                            <div accordion-panel class="mt-0">
                                <div class="para fw-regular">
                                    {{ 'faq.faq_cont_5' | translate}}
                                    <a href="https://x.com/BTCBULL_TOKEN" target="_blank">X (Twitter)</a>
                                    {{ 'faq.faq_cont_5_1' | translate}}
                                    <a href="https://t.me/BTCBULLTOKEN" target="_blank">Telegram</a>
                                    {{ 'faq.faq_cont_5_2' | translate}}
                                </div>
                            </div>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
        </div>
    </div>
    <img src="/assets/images/svg-icons/yellow-bottom-border.svg" class="footer-chain w-100 d-block" alt="border">
</section>